import styles from './index.module.css';
import { useCallback, useEffect, useState } from 'react';
import AppToaster from 'helpers/toaster';
import { EditableText, Intent } from '@blueprintjs/core';
import { SweepPayload } from './types';

type SweepPayloadTableProps = {
  sweepPayload?: SweepPayload;
  setSweepPayload: (input: SweepPayload) => void;
  deltaParameters?: string[],
}

type Parameter = {
  path: string,
  values: string[] | number[],
  isDelta?: boolean,
};

const SweepPayloadTable = (props : SweepPayloadTableProps) => {
  const { sweepPayload, setSweepPayload, deltaParameters } = props;
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [longestParam, setLongestParam] = useState<Parameter>();

  useEffect(() => {
    if (!sweepPayload) return;
    try {
      const parsedParameterPaths = Object.getOwnPropertyNames(sweepPayload.sweep_parameters);
      const parsedParameters = parsedParameterPaths.map((p: string) => {
        return {
          path: p,
          values: sweepPayload.sweep_parameters[p],
        };
      });

      const longestLength = Math.max(...parsedParameters.map(i => i.values.length));
      setLongestParam(parsedParameters.find(p => p.values.length === longestLength));
      setParameters(parsedParameters);
    } catch (e) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Error importing payload',
      });
    }
  }, [sweepPayload]);

  const onCellValueChange = useCallback((parameter: Parameter, value: string, index: number) => {
    if (sweepPayload) {
      const newSweepPayload: SweepPayload = { ...sweepPayload };
      parameter.values[index] = value;
      const newParams = parameters.map(p => {
        if (p.path === parameter.path) {
          newSweepPayload.sweep_parameters[parameter.path] = parameter.values;
          return parameter;
        }
        newSweepPayload.sweep_parameters[p.path] = p.values;
        return p;
      });

      setParameters(newParams);
      setSweepPayload(newSweepPayload);
    }
  }, [parameters]);

  return (
    <table className={styles.payloadTable}>
      {parameters.length > 0 && (
        <>
          <tr className={styles.textCenterColumnHeader}>
            <th>Run</th>
            {parameters.map((parameter) => (
              <th>{parameter.path}</th>
            ))}
          </tr>
          {longestParam?.values.map((p, index) => (
            <tr className={styles.textCenterRow}>
              <td>{index + 1}</td>
              {parameters.map((parameter) => (
                <td>
                  {deltaParameters?.find(d => parameter.path === d) && (<>Δ</>)}
                  <EditableText
                    value={parameter.values[index].toString()}
                    onChange={value => {
                      onCellValueChange(parameter, value, index);
                    }}
                    selectAllOnFocus
                  />
                </td>
              ))}
            </tr>
          ))}
        </>
      )}
    </table>
  );
};

export default SweepPayloadTable;
