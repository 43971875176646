import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Checkbox, H3, Intent } from '@blueprintjs/core';
import _ from 'lodash';

import {
  PartCategory,
  PartConfig,
  useBulkDeletePartConfigsMutation,
  useDeletePartConfigMutation,
  usePartCategoriesQuery,
  usePartConfigsTableLazyQuery,
} from 'graphql/generated/graphql';
import Table, { BulkRowActions, ParamsChangeFn, RowActions } from 'components/Table';
import AppToaster from 'helpers/toaster';
import { useAlert } from 'components/Alert';

import styles from './index.module.css';
import { FilterType } from 'types';
import LinkButton from 'components/LinkButton';

const columnHelper = createColumnHelper<PartConfig>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('display_name', {
    header: 'Display Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('category', {
    header: 'Category',
    cell: info => info.getValue().display_name,
    enableColumnFilter: true,
    meta: {
      filter: {
        type: FilterType.SELECT,
        multiSelect: true,
      },
    },
  }),
  columnHelper.accessor('mileage_limit', {
    header: 'Mileage Limit',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    meta: {
      filter: {
        type: FilterType.NUMBER,
      },
    },
  }),
  columnHelper.accessor('expires', {
    header: 'Expires',
    cell: info => (
      <Checkbox
        checked={info.getValue()}
        className={styles.useInSimCheckbox}
        disabled
      />
    ),
    enableColumnFilter: true,
    meta: {
      filter: {
        type: FilterType.BOOLEAN,
      },
    },
  }),
] as AccessorKeyColumnDef<PartConfig>[];

export default () => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [partCategories, setPartCategories] = useState<PartCategory[]>([]);
  const [tableData, setTableData] = useState<PartConfig[]>([]);

  usePartCategoriesQuery({
    onCompleted: data => setPartCategories(data.partCategories),
  });
  const [getPartConfigs, { data, refetch }] = usePartConfigsTableLazyQuery();
  const [deletePartConfig] = useDeletePartConfigMutation();
  const [bulkDeletePartConfigs] = useBulkDeletePartConfigsMutation();

  // Once, since possible part types is coming from API and cannot be statically
  // defined, adds them to the filter meta once the component is initialized
  useEffect(() => {
    const partConfigColumn = columns.find(c => c.accessorKey === 'category');
    if (partConfigColumn?.meta?.filter) {
      partConfigColumn.meta.filter.selectItems = partCategories.map(c => ({
        label: c.display_name,
        value: c.id,
      }));
    }
  }, [partCategories]);

  useEffect(() => {
    if (data) setTableData(data.partConfigs.rows as PartConfig[]);
  }, [data]);

  const bulkRowActions: BulkRowActions<PartConfig> = [{
    intent: Intent.DANGER,
    label: 'Delete',
    value: rows => {
      const content = (
        <>
          <p>Delete these?</p>
          <ul>
            {rows.map(r => <li>{r.original.display_name}</li>)}
          </ul>
        </>
      );
      alert.showAlert(content, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        bulkDeletePartConfigs({
          variables: { ids: rows.map(r => r.original.id) },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Part config(s) successfully deleted',
            });
            rows.forEach(r => r.toggleSelected());
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting part config(s): ${e.message}`,
            });
          },
        });
      });
    },
  }];
  const rowActions: RowActions<PartConfig> = [{
    label: 'Edit',
    value: row => {
      navigate(`/parts/configs/${row.original.id}`);
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      alert.showAlert(`Delete "${row.original.display_name}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deletePartConfig({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Part config successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting part config: ${e.message}`,
            });
          },
        });
      });
    },
  }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getPartConfigs({
      variables: {
        input: {
          filters: _.mapValues(_.keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
      fetchPolicy: 'no-cache',
    });
  };
  const debouncedOnTableParamsChange = _.debounce(onTableParamsChange, 200);

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Part Configs</H3>
        <LinkButton
          buttonProps={{
            icon: 'plus',
            intent: Intent.PRIMARY,
            text: 'Create Part Config',
          }}
          to="create"
        />
      </div>
      <Table
        id="part-config-summary"
        bulkRowActions={bulkRowActions}
        columns={columns}
        data={tableData}
        persistColumnVisibility
        enableHiding
        enablePagination
        enableRowSelection
        manualFiltering
        manualPagination
        manualSorting
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        rowActions={rowActions}
        totalRowCount={data?.partConfigs.totalCount}
      />
    </>
  );
};
