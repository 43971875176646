import { useRef } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, H3, Intent } from '@blueprintjs/core';

import PartForm from 'components/PartForm';
import { CreatePartInput, PartProperty, useCreatePartMutation } from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { defaultPart, transformPartPropertyValues } from 'helpers/part';

import styles from './index.module.css';
import { find, get } from 'lodash';

interface PartFormMethods {
  getData: () => any | null; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default () => {
  const navigate = useNavigate();
  const form = useForm<CreatePartInput>({ defaultValues: defaultPart });
  const formRef = useRef<PartFormMethods>(null);
  const [createPart] = useCreatePartMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const onSubmit = (input: CreatePartInput) => {
    if (!formRef.current) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Form reference is not available',
      });
      return;
    }

    const data = formRef.current.getData(); // Get data from the child form

    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);

    createPart({
      variables: {
        input: {
          ...input,
          data,
          exp_date: input.exp_date ? input.exp_date : null,
          properties: transformPartPropertyValues(input.properties as PartProperty[]),
          organization_id: get(findOrg, 'id', ''),
          team_id: get(findTeam, 'id', null),
        },
      },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Part successfully added',
        });
        if (data.part) navigate(`/parts/${data.part.id}`);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error adding part: ${e.message}`,
        });
      },
    });
  };

  return (
    <div className={styles.partFormContainer}>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className={styles.headerBar}>
            <H3>Add Part</H3>
            <Button
              className={styles.saveButton}
              icon="floppy-disk"
              intent={Intent.PRIMARY}
              large
              text="Save"
              type="submit"
            />
          </div>
          <PartForm ref={formRef} />
        </form>
      </FormProvider>
    </div>
  );
};
