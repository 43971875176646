import { H3, Intent, Button } from '@blueprintjs/core';
import styles from './index.module.css';
import SweepsTable from 'components/SweepsTable';
import { useState } from 'react';

export default () => {
  const [isAddSweepDialogOpen, setIsAddSweepDialogOpen] = useState<boolean>(false);

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Sweeps Summary</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setIsAddSweepDialogOpen(true)}
          text="Create Sweep"
        />
      </div>
      <SweepsTable
        isAddSweepDialogOpen={isAddSweepDialogOpen}
        onAddSweepDialogClose={() => setIsAddSweepDialogOpen(false)}
      />
    </>
  );
};
