export type SweepPayload = {
  sim_engine?: string,
  sweep_parameters: {[key: string]: string[] | number[]},
}

export type SweepGenerationPayload = {
  design_method: string,
  number_of_runs: number,
  sweep_parameters_definition?: {[key: string]: ParameterDefinition},
}

export type ParameterDefinition = {
  starting_value: number;
  min: number,
  max: number,
}

export enum SweepApiMethods {
  FULLFACTORIAL = 'Full Factorial',
  RANDOM = 'Random Discrete',
}
