import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { find } from 'lodash';
import { H3, Intent } from '@blueprintjs/core';
import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';

import {
  useEightPostsLazyQuery,
  useDriveFilesLazyQuery,
  useDriversLazyQuery,
  useSimStatesLazyQuery,
  useLapTimeDataLazyQuery,
  useLapWindDataLazyQuery,
} from 'graphql/generated/graphql';
import LinkButton from 'components/LinkButton';
import SimDocumentNavMenu from '../SimDocumentNavMenu';
import Table, { RowActions } from 'components/Table';
import { simDocumentCategories  } from '../../../constants';
import { SimDocumentType, SimDocumentCategory } from '../../../types';
import styles from './index.module.css';

type SimDocument = {
  id: number;
  desc: string;
  name: string;
  owner: string;
  track? : string;
  created_at?: string;
  updated_at?: string;
}

export default () => {
  const params = useParams();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<SimDocumentCategory | null>(null);
  const [selectedConfig, setSelectedConfig] = useState<SimDocumentType | null>(null);
  const [tableData, setTableData] = useState<SimDocument[]>([]);

  const columnHelper = createColumnHelper<SimDocument>();
  const columns = [
    columnHelper.accessor('desc', {
      header: 'Description',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('name', {
      header: 'Track',
      cell: info => {
        return selectedConfig?.name === 'driver' ? info.row.original.track : info.getValue();
      },
      enableColumnFilter: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as AccessorKeyColumnDef<SimDocument>[];

  const [getEightPosts] = useEightPostsLazyQuery({
    onCompleted: data => setTableData(data.eightPosts as SimDocument[]),
  });

  const [getDrivers] = useDriversLazyQuery({
    onCompleted: data => setTableData(data.drivers as SimDocument[]),
  });

  const [getLapTimeData] = useLapTimeDataLazyQuery({
    onCompleted: data => setTableData(data.lapTimeDatas as SimDocument[]),
  });

  const [getDriveFiles] = useDriveFilesLazyQuery({
    onCompleted: data => setTableData(data.driveFiles as SimDocument[]),
  });

  const [getLapWindData] = useLapWindDataLazyQuery({
    onCompleted: data => setTableData(data.lapWindDatas as SimDocument[]),
  });

  const [getSimStates] = useSimStatesLazyQuery({
    onCompleted: data => setTableData(data.simStates as SimDocument[]),
  });

  useEffect(() => {
    if (params.categoryName && params.configName) {
      const category = find(simDocumentCategories, (o) => (o.name === params.categoryName));
      if (category) {
        const type = find(category.types, (o) => (o.name === params.configName));
        if (type) setSelectedConfig(type as SimDocumentType);
      }
    }
  }, [params.configName]);

  useEffect(() => {
    if (params.categoryName) {
      const category = find(simDocumentCategories, (o) => (o.name === params.categoryName));
      if (category) {
        setSelectedCategory(category as SimDocumentCategory);
      }
    }
  }, [params.categoryName]);

  useEffect(() => {
    if (selectedConfig) {
      switch (selectedConfig.name) {
        case 'eight_post':
          getEightPosts({ fetchPolicy: 'no-cache' });
          break;
        case 'driver':
          getDrivers({ fetchPolicy: 'no-cache' });
          break;
        case 'lap_time_data':
          getLapTimeData({ fetchPolicy: 'no-cache' });
          break;
        case 'lap_wind_data':
          getLapWindData({ fetchPolicy: 'no-cache' });
          break;
        case 'drive_file':
          getDriveFiles({ fetchPolicy: 'no-cache' });
          break;
        case 'sim_states':
          getSimStates({ fetchPolicy: 'no-cache' });
          break;
        default:
          setTableData([]);
          break;
      }
    }
  }, [selectedConfig]);

  const rowActions: RowActions<SimDocument> = [
    {
      label: 'Edit',
      value: row => {
        if (selectedCategory && selectedConfig) {
          navigate(`/sims/documents/category/${selectedCategory.name}/${selectedConfig.name}/${row.original.id}`);
        }
      },
    },
    {
      label: 'Clone',
      value: row => {
        if (selectedCategory && selectedConfig) {
          navigate(`/sims/documents/category/${selectedCategory.name}/${selectedConfig.name}/clone/${row.original.id}`);
        }
      },
    },
  ];

  if (!selectedCategory || !selectedConfig) return null;

  return (
    <div className={styles.container}>
      <SimDocumentNavMenu />
      <div className={styles.content}>
        <div className={styles.header}>
          <H3>{selectedConfig.displayName}</H3>
          <LinkButton
            buttonProps={{
              icon: 'cube-add',
              intent: Intent.PRIMARY,
              text: 'Add Document',
            }}
            to={{
              pathname: '/sims/documents/add',
              search: `?type=${selectedConfig.name}`,
            }}
          />
        </div>
        <Table
          columns={columns}
          data={tableData}
          enablePagination
          id={`${selectedConfig}_sim_documents`}
          key={selectedConfig.name}
          persistColumnVisibility
          rowActions={rowActions}
          totalRowCount={tableData.length}
        />
      </div>
    </div>
  );
};
