import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useNavigate,
  unstable_useBlocker,      // eslint-disable-line camelcase
  unstable_BlockerFunction, // eslint-disable-line camelcase
  useRouteLoaderData,
} from 'react-router-dom';
import {
  set,
  isEqual,
  cloneDeep,
  has,
  snakeCase,
  find,
  partition,
  map,
  includes,
  chain,
  findIndex,
  filter,
} from 'lodash';
import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Intent,
  ControlGroup,
  Position,
  FormGroup,
  Callout,
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import classNames from 'classnames';

import CreateBranchModal from 'components/CreateBranchModal';
import Select from 'components/Select';
import SetupHistoryModal from 'components/SetupHistoryModal';
import SUITForm, { SetupFieldHandler } from 'components/SUITForm';
import SUITNavigationMenu, { TemplateItemNodeInfo } from 'components/SUITNavigationMenu';
import {
  SetupBranch,
  SetupFieldType,
  SetupField,
  useCommitSetupMutation,
  useSetupByBranchIdLazyQuery,
  useSetupBranchesByRootIdQuery,
  useSUITByIdQuery,
  useSetupFieldsQuery,
  useSUITsQuery,
  useSetupByBranchIdQuery,
  useRunsByRootIdQuery,
  SUIT,
  useSetupBranchByIdQuery,
  SetupByBranchIdDocument,
  SetupBranchesByRootIdDocument,
  SetupByIdDocument,
  Run,
} from 'graphql/generated/graphql';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import { SetupToSetupInput } from 'helpers/converter';
import { deleteProp, excludeSetupMeta } from 'helpers/setup';
import AppToaster from 'helpers/toaster';
import { SUITSlice, selectActiveSUITId } from 'reducers/suit';
import { selectDarkMode } from 'reducers/ui';
import { SelectItem, GQLSetup, SUITNoTemplate } from 'types';
import TitleBar from './TitleBar';

import styles from './index.module.css';
import hasPermission from 'helpers/permissions';
import { PermissionName } from '../../constants';

const SetupIndexPage = () => {
  /**
   * Misc. hooks
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const branchId = Number(params.branchId);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let { getUser: { teams, permissions } } = useRouteLoaderData('root') as any;

  /**
   * Selectors
   */
  const darkMode = useSelector(selectDarkMode);
  const activeSUITId = useSelector(selectActiveSUITId);

  /**
   * Local state
   */
  const [setup, setSetup] = useState<GQLSetup>();
  const [cleanSetup, setCleanSetup] = useState<GQLSetup>();
  const [branch, setBranch] = useState<SetupBranch>();
  const [availableRuns, setAvailableRuns] = useState<Run[]>([]);
  const [availableBranches, setAvailableBranches] = useState<SetupBranch[]>([]);
  const [setupFields, setSetupFields] = useState<SetupField[]>([]);
  const [suitItems, setSUITItems] = useState<SelectItem<SUITNoTemplate>[]>([]);
  const [branchModalOpen, setBranchModalOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [activeSUIT, setActiveSUIT] = useState<SUIT>();

  /**
   * Immediate queries
   */
  useSetupFieldsQuery({
    onCompleted: data => setSetupFields(data.setupFields.rows as SetupField[]),
  });
  useSUITsQuery({
    onCompleted: data => {
      const items: SelectItem<SUITNoTemplate>[] = data.suits.rows.map(s => ({ label: s.name, value: s }));
      setSUITItems(items);
    },
  });
  useSetupByBranchIdQuery({
    variables: { branchId },
    onCompleted: data => {
      setSetup(data.setup as GQLSetup);
      setCleanSetup(data.setup as GQLSetup);
    },
    fetchPolicy: 'cache-and-network',
  });
  useSetupBranchByIdQuery({
    variables: { id: branchId },
    onCompleted: data => {
      setBranch(data.branch as SetupBranch);
    },
  });
  useSetupBranchesByRootIdQuery({
    variables: { rootId: branch?.root.id ?? -1 },
    skip: !branch,
    onCompleted: data => {
      const thisBranch = data.branches.find(b => b.id === branchId);
      if (thisBranch) setBranch(thisBranch as SetupBranch);
      setAvailableBranches(data.branches as SetupBranch[]);
    },
    fetchPolicy: 'cache-and-network',
  });
  useRunsByRootIdQuery({
    variables: { rootId: branch?.root.id ?? -1 },
    skip: !branch,
    onCompleted: data => {
      setAvailableRuns(data.runs as Run[]);
    },
  });
  useSUITByIdQuery({
    variables: { id: activeSUITId ?? -1 },
    skip: !activeSUITId,
    onCompleted: data => setActiveSUIT(data.suit as SUIT),
  });

  /**
   * Lazy queries
   */
  const [commitSetup] = useCommitSetupMutation();
  const [getSetupByBranchId] = useSetupByBranchIdLazyQuery();

  const branchItems = useMemo(() => {
    const items = [];
    const runBranchIds: number[] = map(availableRuns, (ar) => ar.branch.id);

    const mainBranch = find(availableBranches, (ab) => (ab.name === 'main'));
    if (mainBranch) items.push({ label: mainBranch.name, value: mainBranch });

    const partitionedBranches = partition(availableBranches, (ab) => (!includes(runBranchIds, ab.id)));

    const sortedBranches = chain(partitionedBranches[0])
      .filter((b) => b.name !== 'main')
      .sortBy('created_at')
      .map((b) => ({ label: b.name, value: b }))
      .value();

    items.push(...sortedBranches);

    const sortedRuns = chain(partitionedBranches[1])
      .sortBy('created_at')
      .map((b) => ({
        label: b.name,
        value: b,
        labelElement: (<span className={styles.runLabel}>{b.name}</span>),
      }))
      .value();

    items.push(...sortedRuns);

    return items;
  }, [availableBranches, availableRuns]);

  /**
   * Effects
   */
  useEffect(() => {
    const newBranch = availableBranches?.find(b => b.id === branchId);
    if (!newBranch) return;

    setBranch(newBranch);
    getSetupByBranchId({
      variables: { branchId: newBranch.id },
      onCompleted: data => {
        setSetup(data.setup as GQLSetup);
        setCleanSetup(data.setup as GQLSetup);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error refreshing setup: ${e.message}`,
        });
      },
    });
  }, [branchId]);

  useEffect(() => {
    if (!setup || !cleanSetup) return;

    const isDirtyCheck = !isEqual(excludeSetupMeta(cleanSetup), excludeSetupMeta(setup));
    const prevIsDirty = isDirty;
    setIsDirty(isDirtyCheck);
    if (isDirtyCheck !== prevIsDirty) {
      if (isDirtyCheck) addWarningListener();
      else removeWarningListener();
    }
  }, [setup, cleanSetup]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: unstable_BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  unstable_useBlocker(blockerFunc);

  const onBranchChange = async (item: SelectItem<SetupBranch>) => {
    const newBranch = item.value;
    navigate(`/setup/${newBranch.id}`);
  };

  const onSUITChange = (item: SelectItem<SUITNoTemplate>) => {
    dispatch(SUITSlice.actions.setActiveSUITId(item.value.id));
  };

  const onSetupMetaChange = (key: string, value: string | number) => {
    if (!setup) return;

    const newSetup = cloneDeep(setup);

    if (key === 'year') {
      if (Number(setup.year) !== Number(value)) {
        set(newSetup, key, Number(value));
        set(newSetup, 'event', null);
      }
    } else if (key === 'series') {
      if (setup.series !== value) {
        set(newSetup, key, value);
        set(newSetup, 'event', null);
      }
    } else {
      set(newSetup, key, value);
    }

    setSetup(newSetup);
  };

  const onSetupDataChange: SetupFieldHandler = (field, value) => {
    const { path, type } = field;
    if (!setup) return;

    const newSetup = cloneDeep(setup);
    const setupPath = `data.${path}`;

    switch (type) {
      case SetupFieldType.PART:
        set(newSetup, setupPath, value);
        break;
      default: {
        const newValue = value;
        if (value === '' && !has(cleanSetup, setupPath)) {
          // If the original setup *did not have at all (not defined vs undefined)*,
          // remove it again so dirty checking works properly
          deleteProp(newSetup, setupPath.split('.'));
        } else {
          set(newSetup, setupPath, newValue);
        }
      }
    }
    setSetup(newSetup);
  };

  const onSave = async () => {
    if (!setup || !cleanSetup) return;

    // return if no diff
    if (isEqual(excludeSetupMeta(cleanSetup), excludeSetupMeta(setup))) {
      return;
    }

    if (!branch) return;

    await commitSetup({
      variables: {
        branchId: branch.id,
        setup: SetupToSetupInput(setup),
      },
      onCompleted: () => {
        setCleanSetup(setup);
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully saved setup',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error saving setup: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }, { variables }) => {
        if (mutationData?.commitedSetup) {
          cache.writeQuery({
            query: SetupByBranchIdDocument,
            variables: { branchId: variables?.branchId },
            data: {
              setup: { ...mutationData.commitedSetup },
            },
          });

          cache.writeQuery({
            query: SetupByIdDocument,
            variables: { id: mutationData.commitedSetup.id },
            data: {
              setup: mutationData.commitedSetup,
            },
          });

          cache.updateQuery({
            query: SetupBranchesByRootIdDocument,
            variables: { rootId: branch.root.id },
          }, queryData => {
            if (!queryData) return undefined;
            return {
              branches: queryData.branches.map((b: SetupBranch) => {
                if (b.id !== branchId) return b;
                return {
                  ...b,
                  head: {
                    ...b.head,
                    id: mutationData.commitedSetup?.id,
                  },
                };
              }),
            };
          });
        }
      },
    });
  };

  const onBranch = (newBranch: SetupBranch) => {
    setAvailableBranches([...availableBranches, newBranch]);
    setBranchModalOpen(false);
    navigate(`/setup/${newBranch.id}`);
  };

  const onSUITNavItemClick = (path: TemplateItemNodeInfo[]) => {
    const id = path.map(i => snakeCase(i.nodeData?.name)).join('.');
    const sectionElement = document.getElementById(id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const filterSuitNav = (template: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const newTemplate = cloneDeep(template);
    if (hasPermission(PermissionName.SETUP_WRITE, permissions) || hasPermission(PermissionName.SETUP_READ, permissions)) return newTemplate;
    const carIndex = findIndex(template.items, (i: any) => i.name.toLowerCase() === 'car'); // eslint-disable-line @typescript-eslint/no-explicit-any
    if (carIndex !== -1) {
      newTemplate.items[carIndex].items = filter(newTemplate.items[carIndex].items, (item) => {
        return (hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) || hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions));
      });
    }
    return newTemplate;
  };

  if (!setup) return null;

  // If a team setup get team specific permissions
  if (setup.team_id) {
    const findTeam = teams.find((t: { id: string; }) => t.id === setup.team_id);
    permissions = findTeam ? findTeam.permissions.map((p: { name: string; }) => p.name) : [];
  }

  const containerClasses = classNames(styles.mainContainer, { [styles.dark]: darkMode });
  return (
    <>
      <div className={containerClasses}>
        <TitleBar setup={setup} branchId={branchId} onChange={onSetupMetaChange} hideAddSetupsButton={false}>
          <div className={styles.controlsContainer}>
            <FormGroup
              className={styles.suitSelectContainer}
              inline
            >
              <Select
                value={suitItems.find(si => si.value.id === activeSUITId)}
                items={suitItems}
                noSelectionText="Select SUIT"
                onChange={onSUITChange}
                popoverProps={{ className: styles.setupFieldSelectPopover }}
              />
            </FormGroup>
          </div>
        </TitleBar>
        {!activeSUIT && (
          <div>
            <Callout
              intent={Intent.WARNING}
              title="No active SUIT selected!"
            />
          </div>
        )}
        {(branch && activeSUIT) && (
          <div className={styles.bodyContainer}>
            <div className={styles.nav}>
              <Button
                icon="git-commit"
                intent={Intent.PRIMARY}
                onClick={onSave}
                text="Save"
                fill
                disabled={!isDirty}
              />
              <div>
                <ControlGroup className={styles.branchSelectContainer}>
                  <Select
                    fill
                    items={branchItems}
                    noSelectionText="Select Branch"
                    ignoreSelection
                    onChange={onBranchChange}
                    popoverProps={{ className: styles.branchSelectPopover }}
                    value={branchItems.find(i => i.value.name === branch.name)}
                  />
                  <Popover2
                    position={Position.BOTTOM}
                    content={(
                      <Menu>
                        <MenuItem
                          icon="git-branch"
                          onClick={() => setBranchModalOpen(!branchModalOpen)}
                          text="Branch"
                        />
                        <MenuItem
                          icon="git-merge"
                          onClick={() => navigate('merge')}
                          text="Merge"
                        />
                        <MenuDivider />
                        <MenuItem
                          icon="git-repo"
                          onClick={() => setHistoryModalOpen(true)}
                          text="History"
                        />
                      </Menu>
                    )}
                  >
                    <Button icon="chevron-down" />
                  </Popover2>
                </ControlGroup>
              </div>
              <div className={styles.sideNavContainer}>
                <SUITNavigationMenu
                  template={filterSuitNav(activeSUIT.template)}
                  onClick={onSUITNavItemClick}
                />
              </div>
            </div>
            <div className={styles.setupContainer}>
              <SUITForm
                template={activeSUIT.template}
                setupFields={setupFields}
                initialSetup={setup.data}
                team={setup.team_id || undefined}
                onChange={onSetupDataChange}
              />
            </div>
            <CreateBranchModal
              isOpen={branchModalOpen}
              onBranchCreated={onBranch}
              onClose={() => setBranchModalOpen(false)}
              sourceBranch={branch}
            />
          </div>
        )}
      </div>
      {setup.root && (
        <SetupHistoryModal
          isOpen={historyModalOpen}
          onClose={() => setHistoryModalOpen(false)}
          rootId={setup.root!.id} // eslint-disable-line
        />
      )}
    </>
  );
};

export default SetupIndexPage;
