import { H3 } from '@blueprintjs/core';

import SetupSearch from 'components/SetupSearch';

export default () => {
  return (
    <>
      <H3>Setup Summary</H3>
      <SetupSearch />
    </>
  );
};
