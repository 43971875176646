import { Button, Dialog, DialogBody, DialogFooter, EditableText, FormGroup, Intent, Tree, TreeNodeInfo } from '@blueprintjs/core';
import styles from './index.module.css';
import { useParams } from 'react-router-dom';
import { FormProvider, set, useForm } from 'react-hook-form';
import { Part,
  PartConfig,
  SetupField,
  Sweep,
  SweepGroup,
  SweepGroupInput,
  SweepMethod,
  SweepParameter,
  SweepParameterApplicationMethod,
  SweepParameterGenerationMethod,
  SweepParameterInput,
  SweepPart,
  SweepPartInput,
  UpdateSweepInput,
  usePartConfigsQuery,
  useSetupFieldsQuery,
  useSweepByIdQuery,
  useUpdateSweepMutation } from 'graphql/generated/graphql';
import { cloneDeep, toString, toNumber } from 'lodash';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import Accordion from 'components/Accordion';
import { RHFNumericInput, RHFTextArea } from 'components/RHFInputs';
import SweepPartRow from './SweepPartRow';
import SweepParameterRow from './SweepParameterRow';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import SweepPayloadTable from './SweepPayloadTable';
import { useSelector } from 'react-redux';
import SweepGroupRow from './SweepGroupRow';
import axios from 'axios';
import { getTokenFromCookies } from '../../graphql';
import { SweepGenerationPayload, SweepPayload, SweepApiMethods } from './types';

export type SweepForm = {
  name?: string;
  method: SweepMethod;
  numRun?: number;
  parameters?: Partial<SweepParameter>[];
  parts?: Partial<SweepPart>[];
  data?: string;
  sweep_groups?: Partial<SweepGroup>[];
}

const nodes: TreeNodeInfo[] = [
  {
    id: 0,
    hasCaret: false,
    label: 'Config',
  },
  {
    id: 1,
    hasCaret: false,
    label: 'Sweep Payload',
  },
];

export default () => {
  const params = useParams();
  const sweepId = Number(params.sweepId);
  const darkMode = useSelector(selectDarkMode);
  const [sweep, setSweep] = useState<Sweep>();
  const [sweepPayload, setSweepPayload] = useState<SweepPayload>();
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [partConfigs, setPartConfigs] = useState<PartConfig[]>([]);
  const [setupFields, setSetupFields] = useState<SetupField[]>([]);

  const form = useForm<SweepForm>({
    defaultValues: {
      name: '',
      method: SweepMethod.RANDOM,
      numRun: 0,
      data: '',
      parameters: [],
      parts: [],
      sweep_groups: [],
    },
  });

  const { setValue, getValues, watch } = form;

  const [updateSweep] = useUpdateSweepMutation();

  usePartConfigsQuery({
    onCompleted: data => setPartConfigs(data.partConfigs.rows as PartConfig[]),
  });
  useSetupFieldsQuery({
    onCompleted: data => setSetupFields(data.setupFields.rows as SetupField[]),
  });

  const { loading } = useSweepByIdQuery({
    variables: { id: sweepId },
    skip: !sweepId,
    onCompleted: data => {
      if (data.sweep) {
        const sweepParameters = data.sweep.parameters as SweepParameter[];
        const sweepParts = data.sweep.parts?.map(sp => {
          const parts = sp.parts?.map(part => part as Part);
          return {
            id: sp.id,
            enabled: sp.enabled,
            part_config_id: sp.part_config_id,
            part_config: sp.part_config as PartConfig,
            position: sp.position,
            parts,
            sweep_group_id: sp.sweep_group_id,
            sweep_id: sp.sweep_id,
          };
        });
        const sweepGroups = data.sweep.sweep_groups?.map(sg => {
          const parts = sg.parts?.map(sp => {
            return sp as SweepPart;
          });
          return {
            id: sg.id,
            enabled: sg.enabled,
            name: sg.name,
            parameters: sg.parameters,
            parts,
          };
        });
        const sweepPayloadText = data.sweep.data ? JSON.stringify(data.sweep.data) : undefined;
        setSweep({
          id: data.sweep.id,
          method: data.sweep.method,
          name: data.sweep.name,
          owner: data.sweep.owner,
          num_run: data.sweep.num_run,
          parameters: sweepParameters,
          parts: sweepParts,
          sweep_groups: sweepGroups,
          data: sweepPayloadText,
          organization_id: data.sweep.organization_id,
          organization_name: data.sweep.organization_name,
          series: data.sweep.series,
        });
        form.reset({
          name: data.sweep.name,
          method: data.sweep.method,
          numRun: data.sweep.num_run ?? undefined,
          parameters: sweepParameters,
          parts: sweepParts,
          data: sweepPayloadText,
          sweep_groups: sweepGroups,
        });
        setSweepPayload(data.sweep.data);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const method = watch('method');
  const numRun = watch('numRun');
  const parameters = watch('parameters');
  const parts = watch('parts');
  const sweepData = watch('data');
  const groups = watch('sweep_groups');

  const onSweepMetaChange = (target: string, value: string | number) => {
    if (!sweep) return;

    const newSweep = cloneDeep(sweep);
    set(newSweep, target, value);
    setSweep(newSweep);
  };

  const handlePayloadImport = useCallback(() => {
    const parsedPayload = parsePayload(sweepData);
    setSweepPayload(parsedPayload);
    setImportDialogOpen(false);
  }, [sweepData]);

  const parsePayload = (payloadString?: string) => {
    if (payloadString && payloadString.length > 0) {
      try {
        const parsedPayload = JSON.parse(payloadString) as SweepPayload;
        return parsedPayload;
      } catch (e) {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Error importing payload',
        });
      }
    }
    return undefined;
  };

  const sweepPartToSweepPartInput = (sweepPart: Partial<SweepPart>): SweepPartInput => {
    return {
      id: sweepPart.id,
      enabled: sweepPart.enabled,
      part_config_id: sweepPart.part_config_id,
      parts: sweepPart.parts?.map(p => { return { id: p.id }; }),
      position: sweepPart.position,
      sweep_group_id: sweepPart.sweep_group_id,
      sweep_id: sweepPart.sweep_id,
    } as SweepPartInput;
  };

  const sweepParameterToSweepParameterInput = (sweepParameter: Partial<SweepParameter>): SweepParameterInput => {
    return {
      ...sweepParameter,
      min: toNumber(sweepParameter.min),
      inc: toNumber(sweepParameter.inc),
      max: toNumber(sweepParameter.max),
    } as SweepParameterInput;
  };

  const onSubmit = useCallback((input: SweepForm) => {
    if (sweep) {
      const sweepParameters = input.parameters?.filter(sp => !sp.sweep_group_id)?.map(sp => sweepParameterToSweepParameterInput(sp));
      const sweepParts = input.parts?.filter(sp => !sp.sweep_group_id)?.map(sp => sweepPartToSweepPartInput(sp));
      const sweepGroups = input.sweep_groups?.map(sg => {
        const sweepGroupParts = sg.parts?.map(sp => sweepPartToSweepPartInput(sp));
        const sweepGroupParameters = sg.parameters?.map(sp => sweepParameterToSweepParameterInput(sp));
        return {
          ...sg,
          sweep_id: sweep.id,
          parameters: sweepGroupParameters,
          parts: sweepGroupParts,
        } as SweepGroupInput;
      });
      const updateSweepInput: UpdateSweepInput = {
        id: sweep.id,
        name: input.name || '',
        method: input.method,
        num_run: toNumber(input.numRun),
        parameters: sweepParameters,
        parts: sweepParts,
        data: sweepPayload,
        sweep_groups: sweepGroups,
      };
      updateSweep({
        variables: {
          input: {
            ...updateSweepInput,
          },
        },
        onCompleted: () => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Sweep successfully updated',
          });

          form.reset({ ...input });
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error updating Sweep: ${e.message}`,
          });
        },
      });
    }
  }, [sweep, sweepPayload]);

  const handleAddParameter = () => {
    const currentParameters = getValues('parameters') ?? [];
    const newParameter: Partial<SweepParameter> = {
      enabled: false,
      name: '',
      param_application_method: SweepParameterApplicationMethod.ABSOLUTE,
      param_generation_method: SweepParameterGenerationMethod.STEP,
      path: '',
      sweep_group_id: undefined,
    };

    setValue('parameters', [...currentParameters, newParameter]);
  };

  const handleAddPart = () => {
    const currentParts = getValues('parts') ?? [];
    const newPart: Partial<SweepPart> = {
      enabled: false,
      part_config: undefined,
      parts: [],
      position: undefined,
      sweep_id: sweepId,
      sweep_group_id: undefined,
    };

    setValue('parts', [...currentParts, newPart]);
  };

  const handleAddGroup = () => {
    const currentGroups = getValues('sweep_groups') ?? [];
    const newGroup: Partial<SweepGroup> = {
      enabled: false,
      name: '',
      parameters: [],
      parts: [],
    };

    setValue('sweep_groups', [...currentGroups, newGroup]);
  };

  const handleNodeClick = useCallback((node: TreeNodeInfo) => {
    const sectionElement = document.getElementById(`section-${node.id}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const generatePayload = async () => {
    const headers = {
      'Cookie': `apex_platform_token=${getTokenFromCookies()}`,
      'Content-Type': 'application/json',
    };
    const method = getValues('method');

    const body: SweepGenerationPayload = {
      design_method: SweepApiMethods[method],
      number_of_runs: toNumber(getValues('numRun')),
      sweep_parameters_definition: {},
    };

    getValues('parameters')?.map((param) => {
      if (param.enabled && param.path && body.sweep_parameters_definition) {
        body.sweep_parameters_definition[param.path] = {
          starting_value: 0,
          min: toNumber(param.min),
          max: toNumber(param.max),
        };
      }
      return param;
    });
    const { data } = await axios.post('https://dev.apex-setup.app.apex-mp.com/chassis-tuner-tank/api/experiments/generate-inputs', body, { headers });
    const payload = { sweep_parameters: data } as SweepPayload;
    setSweepPayload(payload);
    setValue('data', JSON.stringify(payload));
  };

  const getDeltaParameters = useCallback(() => {
    const deltaParameters = parameters?.filter(p => p.param_application_method === SweepParameterApplicationMethod.DELTA);
    return deltaParameters?.map(p => p.path ?? '') ?? [];
  }, [parameters]);

  return (
    <FormProvider {...form}>
      <form className={styles.mainForm} onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.titleBar}>
          <EditableText
            className={styles.titleValue}
            value={sweep?.name}
            onChange={value => {
              onSweepMetaChange('name', value);
              setValue('name', value);
            }}
            placeholder="Sweep name"
          />
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.nav}>
            <Button
              className={styles.saveButton}
              intent={Intent.PRIMARY}
              type="submit"
              text="Save"
              fill
              loading={loading}
            />
            <div className={styles.sideNavContainer}>
              <Tree
                contents={nodes}
                onNodeClick={handleNodeClick}
              />
            </div>
            <Button
              className={styles.saveButton}
              intent={Intent.NONE}
              type="button"
              text="Generate Payload"
              fill
              onClick={generatePayload}
              loading={loading}
            />
            <Button
              className={styles.saveButton}
              intent={Intent.NONE}
              type="button"
              text="Import Payload"
              fill
              onClick={() => setImportDialogOpen(true)}
              loading={loading}
            />
          </div>
          <div className={styles.sweepContainer}>
            <Accordion
              className={styles.itemContainer}
              id="section-0"
              initialOpen
              key="config"
              title="Config"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.envInput}
                    label="Method"
                    inline
                  >
                    <span>{method && SweepMethod[method]}</span>
                  </FormGroup>
                </div>
                {sweep?.method === SweepMethod.RANDOM && (
                  <div className={styles.inputRow}>
                    <FormGroup
                      className={classNames(styles.formGroup, styles.flex)}
                      contentClassName={styles.envInput}
                      label="Number of Runs"
                      inline
                    >
                      <RHFNumericInput
                        controllerProps={{
                          name: 'numRun',
                        }}
                        inputProps={{
                          fill: true,
                          className: styles.inline,
                          value: toString(numRun),
                        }}
                        useStringValue
                      />
                    </FormGroup>
                  </div>
                )}
                {sweep?.method === SweepMethod.FULLFACTORIAL && (
                  <>
                    <div className={styles.groupHeader}>
                      <div className={styles.addButton}>
                        <Button
                          intent={Intent.PRIMARY}
                          type="button"
                          text="Add Group"
                          icon="plus"
                          small
                          onClick={handleAddGroup}
                        />
                      </div>
                    </div>
                    <div className={styles.group}>
                      {groups?.map((sg, index) => {
                        return (
                          <SweepGroupRow
                            group={sg}
                            groupIndex={index}
                            setupFields={setupFields}
                            partConfigs={partConfigs}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
                {sweep?.method === SweepMethod.RANDOM && (
                  <Accordion
                    className={styles.itemContainer}
                    initialOpen
                    key="parameters"
                    title="Parameters"
                    buttonProps={{
                      className: styles.accordionHeader,
                    }}
                  >
                    <div className={styles.sectionContainer}>
                      <div className={styles.headerRow}>
                        <div className={styles.inline}>
                          <div className={styles.addButton}>
                            <Button
                              intent={Intent.PRIMARY}
                              type="button"
                              text="Add Parameter"
                              fill
                              icon="plus"
                              small
                              onClick={handleAddParameter}
                            />
                          </div>
                        </div>
                        <div className={styles.inlineCheckbox}> </div>
                        <div className={styles.inlineCheckbox}> </div>
                        <h6 className={classNames(styles.inline, styles.pathHeader)}>Path</h6>
                        <h6 className={styles.inlineSelect}>Absolute/Delta</h6>
                        <h6 className={styles.inlineSmall}>Min</h6>
                        <h6 className={styles.inlineSmall}>Max</h6>
                        <div className={styles.inlineCheckbox}> </div>
                      </div>
                      {parameters?.map((p, index) => {
                        return (
                          <SweepParameterRow
                            sweepParameter={p}
                            index={index}
                            setupFields={setupFields}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                )}
                {sweep?.method === SweepMethod.RANDOM && (
                  <Accordion
                    className={styles.itemContainer}
                    initialOpen
                    key="parts"
                    title="Parts"
                    buttonProps={{
                      className: styles.accordionHeader,
                    }}
                  >
                    <div className={styles.sectionContainer}>
                      <div className={styles.headerRow}>
                        <div className={styles.inline}>
                          <div className={styles.addButton}>
                            <Button
                              intent={Intent.PRIMARY}
                              type="button"
                              text="Add Part"
                              fill
                              icon="plus"
                              small
                              onClick={handleAddPart}
                            />
                          </div>
                        </div>
                        <div className={styles.inlineCheckbox}> </div>
                        <h6 className={classNames(styles.inline, styles.partsHeader)}>Part(s)</h6>
                        <h6 className={styles.inlineSelect}>Position</h6>
                        <div className={styles.inlineCheckbox}> </div>
                      </div>
                      {parts?.map((p, index) => {
                        return (
                          <SweepPartRow
                            sweepPart={p}
                            index={index}
                            partConfigs={partConfigs}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                )}
              </div>
            </Accordion>
            <Accordion
              className={styles.itemContainer}
              id="section-0"
              initialOpen
              key="sweep payload"
              title="Sweep Payload"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              {sweepPayload && (
                <div className={styles.sectionContainer}>
                  <SweepPayloadTable
                    sweepPayload={sweepPayload}
                    setSweepPayload={setSweepPayload}
                    deltaParameters={getDeltaParameters()}
                  />
                </div>
              )}
            </Accordion>
          </div>
        </div>
        <Dialog
          className={classNames({ 'bp4-dark': darkMode })}
          isOpen={importDialogOpen}
          onClose={() => setImportDialogOpen(false)}
          title="Import Payload"
        >
          <DialogBody className={styles.editorContainer}>
            <RHFTextArea
              controllerProps={{
                name: 'data',
              }}
              textAreaProps={{
                className: styles.importEditor,
                fill: true,
                small: true,
              }}
            />
          </DialogBody>
          <DialogFooter
            actions={(
              <Button
                intent="primary"
                text="Import"
                onClick={() => handlePayloadImport()}
              />
            )}
          />
        </Dialog>
      </form>
    </FormProvider>
  );
};
