import { useMemo, useState, useEffect } from 'react';
import {
  useNavigate,
  useParams,
  unstable_useBlocker,      // eslint-disable-line camelcase
  unstable_BlockerFunction, // eslint-disable-line camelcase
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  Button,
  FormGroup,
  Intent,
  NonIdealState,
  Callout,
  Dialog,
  DialogBody,
  DialogFooter,
  InputGroup,
} from '@blueprintjs/core';
import { snakeCase, map, get, omit } from 'lodash';
import classNames from 'classnames';

import { useAlert } from 'components/Alert';
import RUITForm from 'components/RUITForm';
import Select from 'components/Select';
import {
  RUIT,
  Run,
  RunField,
  RunsByRootIdDocument,
  Setup,
  SetupBranch,
  Session,
  SetupBranchesByRootIdDocument,
  useBulkUpdateRunsMutation,
  useCreateRunMutation,
  useDeleteRunMutation,
  useRUITByIdQuery,
  useRUITsQuery,
  useRunFieldsQuery,
  useRunsByRootIdQuery,
  useSetupBranchByIdQuery,
  useSetupByBranchIdQuery,
  useCloneRunMutation,
  useUpdateRunMutation,
  useSessionsByEventLazyQuery,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { RUITSlice, selectActiveRUITId } from 'reducers/ruit';
import { selectDarkMode } from 'reducers/ui';
import { SelectItem } from 'types';
import TitleBar from '../TitleBar';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';

import styles from './index.module.css';

interface RunsInput {
  runs: Run[];
}

const defaultRunSessionItems = [
  { label: 'Sim', value: 'sim' },
];

const generateDefaultRunData = (runFields: RunField[]): Record<string, unknown> => {
  return runFields.reduce((acc, field) => {
    if (field.positions && field.positions.length > 0) {
      field.positions.forEach(p => {
        const key = `${field.name}_${snakeCase(p.label)}`;
        acc[key] = '';
      });
    } else {
      acc[field.name] = '';
    }
    return acc;
  }, {} as Record<string, unknown>);
};

export default () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const branchId = Number(params.branchId);

  const activeRUITId = useSelector(selectActiveRUITId);
  const darkMode = useSelector(selectDarkMode);

  const [branch, setBranch] = useState<SetupBranch>();
  const [runFields, setRunFields] = useState<RunField[]>([]);
  const [ruitItems, setRUITItems] = useState<SelectItem<RUIT>[]>([]);
  const [activeRUIT, setActiveRUIT] = useState<RUIT>();
  const [setup, setSetup] = useState<Setup>();
  const [isAddRunModalOpen, setIsAddRunModalOpen] = useState(false);
  const [runSessionItems, setRunSessionItems] =  useState<SelectItem<string>[]>(defaultRunSessionItems);
  const [runSession, setRunSession] = useState<string>('sim');
  const [newRunName, setNewRunName] = useState<string>('');

  const defaultRunData = useMemo(() => {
    return generateDefaultRunData(runFields);
  }, [runFields]);

  const form = useForm<RunsInput>({ defaultValues: { runs: [] } });
  const { control, formState, handleSubmit, getValues } = form;
  const { dirtyFields, isDirty } = formState;
  const { fields: runsFields } = useFieldArray({
    control,
    keyName: 'key',
    name: 'runs',
  });

  useRunFieldsQuery({
    onCompleted: data => setRunFields(data.runFields.rows),
  });
  useRUITsQuery({
    onCompleted: data => setRUITItems(data.ruits.rows.map(r => ({ label: r.name, value: r }))),
  });
  useSetupBranchByIdQuery({
    variables: { id: branchId },
    onCompleted: data => {
      if (data.branch) setBranch(data.branch as SetupBranch);
    },
  });
  useSetupByBranchIdQuery({
    variables: { branchId },
    onCompleted: data => setSetup(data.setup as Setup),
  });
  useRunsByRootIdQuery({
    variables: { rootId: branch?.root.id ?? -1 },
    skip: !branch,
    onCompleted: data => form.reset({ runs: data.runs }),
  });
  useRUITByIdQuery({
    variables: { id: activeRUITId || -1 },
    skip: !activeRUITId,
    onCompleted: data => setActiveRUIT(data.ruit as RUIT),
  });

  const [getSessionsByEvent] = useSessionsByEventLazyQuery({
    onCompleted: (data) => {
      const newSessionItems = [
        ...defaultRunSessionItems,
        ...map(data.sessionsByEvent, (s: Session) => ({ label: s.name, value: s.id })),
      ];
      setRunSessionItems(newSessionItems);
    },
  });

  const [createRun] = useCreateRunMutation();
  const [updateRuns] = useBulkUpdateRunsMutation();
  const [deleteRun] = useDeleteRunMutation();
  const [cloneRun] = useCloneRunMutation();
  const [updateRun] = useUpdateRunMutation();

  useEffect(() => {
    if (setup && setup.event && setup.year && setup.series) {
      getSessionsByEvent({
        variables: {
          year: setup.year,
          series: setup.series,
          name: setup.event,
        },
      });
    }
  }, [setup?.event]);

  useEffect(() => {
    if (isDirty) {
      addWarningListener();
    } else {
      removeWarningListener();
    }
  }, [isDirty]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: unstable_BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  unstable_useBlocker(blockerFunc);

  const onRUITChange = (item: SelectItem<RUIT>) => {
    dispatch(RUITSlice.actions.setActiveRUITId(item.value.id));
  };

  const handleOpenCreateRunModal = () => {
    setIsAddRunModalOpen(true);
  };

  const handleCloseCreateRunModal = () => {
    setIsAddRunModalOpen(false);
    setRunSession('sim');
    setNewRunName('');
  };

  const onCreateRun = async () => {
    await createRun({
      variables: {
        fromBranchId: Number(params.branchId),
        name: newRunName,
        session: get(runSessionItems.find(i => i.value === runSession), 'label', ''),
        sessionId: runSession === 'sim' ? '' : runSession,
        input: {
          data: { ...defaultRunData },
        },
      },
      update: (cache, { data: mutationData }) => {
        if (mutationData?.run && branch) {
          cache.updateQuery({
            query: SetupBranchesByRootIdDocument,
            variables: { rootId: branch.root.id },
          }, queryData => {
            if (!queryData) return undefined;
            return {
              branches: [...queryData.branches, mutationData.run?.branch],
            };
          });

          cache.updateQuery({
            query: RunsByRootIdDocument,
            variables: { rootId: branch.root.id },
          }, queryData => {
            if (!queryData) return undefined;
            form.reset({ runs: [...queryData.runs, mutationData.run] });
            return {
              runs: [...queryData.runs, mutationData.run],
            };
          });
        }
      },
    });
    setIsAddRunModalOpen(false);
    setRunSession('sim');
    setNewRunName('');
  };

  const onDeleteRun = async (index: number) => {
    const run = runsFields[index];
    alert.showAlert(
      <p>Are you sure you want to delete this run? This cannot be undone.</p>,
      {
        icon: 'trash',
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      },
    ).then(async confirm => {
      if (!confirm) return;

      await deleteRun({
        variables: { id: run.id },
        onCompleted: () => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Successfully deleted run',
          });
          const filterRuns = runsFields.filter((r: Run) => run.id !== r.id);
          form.reset({ runs: filterRuns });
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Failed to delete run: ${e.message}`,
          });
        },
        update: (cache, { data: mutationData }) => {
          if (mutationData?.deleteCount && branch) {
            cache.updateQuery({
              query: SetupBranchesByRootIdDocument,
              variables: { rootId: branch.root.id },
            }, queryData => {
              if (!queryData) return undefined;
              return {
                branches: queryData.branches.filter((b: SetupBranch) => {
                  return b.id !== run.branch.id;
                }),
              };
            });

            // Evicts all references to the deleted object from the cache
            cache.evict({ id: cache.identify(run) });
            cache.gc();

            // This needs to happen here rather than the onCompleted hook
            // Otherwise the URL and view are updated and this doesn't work
            // (why?)
            if (branchId === run.branch.id && branch) {
              navigate(`/setup/${branch.root.id}/run-log`);
            }
          }
        },
      });
    });
  };

  const onCloneRun = async (index: number) => {
    const run = runsFields[index];
    const dirtyInputRuns = dirtyFields.runs ?? [];
    const isRunDirty = !!dirtyInputRuns[index];
    let updateRunFailed = false;

    if (isRunDirty) {
      const values = getValues();
      const dirtyRun = omit(values.runs[index], ['session', 'session_id']);

      await updateRun({
        variables: {
          input: dirtyRun,
        },
        onError: e => {
          updateRunFailed = true;
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Failed to save run: ${e.message}`,
          });
        },
      });
    }

    if (!updateRunFailed) {
      await cloneRun({
        variables: {
          id: run.id,
          fromBranchId: Number(params.branchId),
        },
        onCompleted: () => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Successfully cloned run',
          });
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Failed to clone run: ${e.message}`,
          });
        },
        update: (cache, { data: mutationData }) => {
          if (mutationData?.run && branch) {
            cache.updateQuery({
              query: SetupBranchesByRootIdDocument,
              variables: { rootId: branch.root.id },
            }, queryData => {
              if (!queryData) return undefined;
              return {
                branches: [...queryData.branches, mutationData.run?.branch],
              };
            });

            cache.updateQuery({
              query: RunsByRootIdDocument,
              variables: { rootId: branch.root.id },
            }, queryData => {
              if (!queryData) return undefined;
              form.reset({ runs: [...queryData.runs, mutationData.run] });
              return {
                runs: [...queryData.runs, mutationData.run],
              };
            });
          }
        },
      });
    }
  };

  const onSubmit = async (input: RunsInput) => {
    // RHF doesn't tell us *which* fields in a field array are dirty (by ID)
    // BUT it does preserve their index, so if the second field is dirty and
    // NOT the first, `dirtyFields.runs` will look like `[undefined, { ...run2 }]`
    //
    // Thus, the dirty runs can be derived by skipping empty slots in the dirty
    // runs array
    const dirtyInputRuns = dirtyFields.runs ?? [];
    const dirtyIndices = dirtyInputRuns.reduce((acc, run, index) => {
      if (run) acc.push(index);
      return acc;
    }, [] as number[]);
    const dirtyRuns = dirtyIndices.map(i => omit(input.runs[i], ['session', 'session_id']));

    updateRuns({
      variables: {
        inputs: dirtyRuns,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully saved runs',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to save runs: ${e.message}`,
        });
      },
    });
  };

  // TODO: Show loading
  if (!setup) return null;

  return (
    <div className={styles.mainContainer}>
      <TitleBar setup={setup} branchId={branchId} hideAddSetupsButton>
        <FormGroup
          className={styles.ruitSelectContainer}
          inline
        >
          <div className={styles.controlsContainer}>
            <Select
              value={ruitItems.find(ri => ri.value.id === activeRUITId)}
              items={ruitItems}
              noSelectionText="Select RUIT"
              onChange={onRUITChange}
              popoverProps={{ className: styles.setupFieldSelectPopover }}
            />
          </div>
        </FormGroup>
        <div className={styles.titleButtons}>
          <Button
            className={styles.addRunButton}
            disabled={!isDirty}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            onClick={handleSubmit(onSubmit)}
            text="Save"
          />
          <Button
            icon="plus"
            text="Create Run"
            className={styles.saveButton}
            onClick={handleOpenCreateRunModal}
            disabled={isDirty}
          />
        </div>
      </TitleBar>

      {!activeRUIT && (
        <div>
          <Callout
            intent={Intent.WARNING}
            title="No active RUIT selected!"
          />
        </div>
      )}

      {runsFields.length === 0 && (
        <div>
          <NonIdealState
            icon="search"
            title="No runs created"
            description="No runs have been created for this setup yet"
          />
        </div>
      )}

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {runsFields.map((run, index) => (
            <div key={`run-${run.key}`} className={styles.runsContainer}>
              {activeRUIT && (
                <div className={styles.runContainer}>
                  <RUITForm
                    runFields={runFields}
                    runIndex={index}
                    template={activeRUIT?.template}
                    baseline={index === 0 ? undefined : runsFields[index - 1] as Run}
                  />
                  <div className={styles.buttonsContainer}>
                    <Button
                      className={styles.runOptionButton}
                      icon="trash"
                      intent={Intent.DANGER}
                      minimal
                      onClick={() => onDeleteRun(index)}
                    />
                    <Button
                      className={styles.runOptionButton}
                      icon="duplicate"
                      minimal
                      onClick={() => onCloneRun(index)}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </form>
      </FormProvider>

      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isAddRunModalOpen}
        onClose={handleCloseCreateRunModal}
        title="Create Run"
      >
        <DialogBody>
          <div>
            <div className={styles.createRunContainerRow}>
              <div className={styles.runSessionLabel}>Run Name</div>
              <InputGroup
                fill
                placeholder="Enter Run Name (optional)"
                onChange={e => setNewRunName(e.target.value)}
              />
            </div>
            <div className={styles.createRunContainerRow}>
              <div className={styles.runSessionLabel}>Select Session</div>
              <Select
                initialItem={runSessionItems.find(i => i.value === runSession)}
                items={runSessionItems}
                noSelectionText="Session"
                onChange={item => setRunSession(item.value)}
                fill
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => onCreateRun()}
            />
          )}
        />
      </Dialog>
    </div>
  );
};
