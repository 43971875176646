import { Link } from 'react-router-dom';
import { H3, H4 } from '@blueprintjs/core';

import SimDocumentNavMenu from '../SimDocumentManagement/SimDocumentNavMenu';
import { simDocumentCategories  } from '../../constants';
import styles from './index.module.css';

export default () => {
  return (
    <div className={styles.container}>
      <SimDocumentNavMenu />
      <div className={styles.categoriesListContainer}>
        <H3>All Categories</H3>
        {simDocumentCategories.map((category) => {
          return (
            <div key={category.name}>
              <H4 className={styles.categoryListHeading}>{category.displayName}</H4>
              <ul className={styles.categoryList}>
                {category.types.map(type => (
                  <li key={`type-${type.name}`}>
                    <Link
                      className={styles.configLink}
                      to={`category/${category.name}/${type.name}`}
                    >
                      {type.displayName}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};
