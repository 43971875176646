import { Part, PartConfig, SweepPart, usePartConfigPartsQuery } from 'graphql/generated/graphql';
import styles from './index.module.css';
import { get, useFormContext } from 'react-hook-form';
import { Intent, Button } from '@blueprintjs/core';
import { RHFCheckbox, RHFNumericInput, RHFSelect } from 'components/RHFInputs';
import { useEffect, useState } from 'react';
import { SelectItem } from 'types';
import MultiSelect from 'components/MultiSelect';

type SweepPartRowProps = {
  sweepPart: Partial<SweepPart>;
  index: number;
  partConfigs: PartConfig[];
  prefix?: string;
  isInGroup?: boolean;
}

const Positions = [
  { label: 'LF', value: 'LF' },
  { label: 'RF', value: 'RF' },
  { label: 'LR', value: 'LR' },
  { label: 'RR', value: 'RR' },
];

const SweepPartRow = ({ sweepPart, index, partConfigs, prefix = '', isInGroup = false } : SweepPartRowProps) => {
  const { control, formState: { errors }, getValues, setValue, watch } = useFormContext();
  const [partList, setPartList] = useState<Part[]>([]);
  const [partSelectItems, setPartsSelectItems] = useState<SelectItem<Part>[]>([]);
  const [selectedParts, setSelectedParts] = useState<Part[]>(sweepPart.parts?.map(p => p as Part) ?? []);

  const partConfigItems = partConfigs.map(p => {
    return { label: p.display_name, value: p.id };
  });

  const selectedPartConfigId = watch(`${prefix}parts.${index}.part_config_id`);

  const { refetch } = usePartConfigPartsQuery({
    variables: { input: { filters: { part_config: selectedPartConfigId } } },
    onCompleted: data => {
      setPartList(data?.parts?.rows?.filter(p => p.serial_number).map(p => {
        return p as Part;
      }));
    },
  });

  useEffect(() => {
    refetch();
    const selectedPartConfig = partConfigs.find(pc => pc.id === selectedPartConfigId);
    setValue(`${prefix}parts.${index}.part_config`, selectedPartConfig);
  }, [selectedPartConfigId]);

  useEffect(() => {
    setPartsSelectItems(partList.map(p => partToSelectItem(p)));
  }, [partList]);

  useEffect(() => {
    setValue(`${prefix}parts.${index}.parts`, selectedParts);
  }, [selectedParts]);

  const partToSelectItem = (part: Part) => {
    return {
      label: part.serial_number?.toString() ?? '',
      value: part,
    };
  };

  const handleRemovePart = () => {
    const currentSweepParts = getValues(`${prefix}parts`) ?? [];
    currentSweepParts.splice(index, 1);
    setValue(`${prefix}parts`, currentSweepParts);
  };

  return (
    <div className={styles.inputRow}>
      {!isInGroup && (
        <RHFCheckbox
          controllerProps={{
            control,
            name: `${prefix}parts.${index}.enabled`,
          }}
          checkboxProps={{
            className: styles.inlineCheckbox,
            checked: sweepPart.enabled,
          }}
        />
      )}
      <RHFNumericInput
        controllerProps={{
          control,
          name: `${prefix}parts.${index}.part_config.display_name`,
        }}
        inputProps={{
          fill: true,
          className: styles.inline,
          value: partConfigs.find(p => p.id === getValues(`${prefix}parts.${index}.part_config_id`))?.display_name,
        }}
        useStringValue
      />
      <div className={styles.inlineButton}>
        <RHFSelect
          controllerProps={{
            control,
            name: `${prefix}parts.${index}.part_config_id`,
          }}
          items={partConfigItems}
          selectProps={{
            fill: true,
            filterable: true,
          }}
          buttonProps={{
            rightIcon: 'more',
            text: '',
          }}
        />
      </div>
      <div className={styles.inline}>
        <MultiSelect
          items={partSelectItems}
          onChange={updatedItems => {
            const parts = updatedItems.map(i => i.value);
            if (sweepPart.id) setValue(`${prefix}parts.${index}.parts`, parts);
            setSelectedParts(parts);
          }}
          selectedItems={selectedParts.map(p => partToSelectItem(p))}
          selectProps={{
            // Prevents selecting an item from dismissing this popover as well
            popoverProps: { captureDismiss: true },
            fill: true,
            disabled: !partSelectItems.length,
          }}
        />
      </div>
      <div className={styles.inlineSelect}>
        <RHFSelect
          controllerProps={{
            control,
            name: `${prefix}parts.${index}.position`,
          }}
          intent={get(errors, 'position') && Intent.DANGER}
          items={Positions}
          fill
        />
      </div>
      <div className={styles.inlineButton}>
        <Button
          intent={Intent.DANGER}
          type="button"
          icon="cross"
          minimal
          onClick={handleRemovePart}
        />
      </div>
    </div>
  );
};

export default SweepPartRow;
