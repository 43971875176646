import { useRef, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { get } from 'lodash';
import * as d3 from 'd3';

interface DataPoint {
  displayName?: string,
  id: number,
  color: string,
  name: string;
  x: number;
  y: number;
}

interface ChartProps {
  title?: string;
  data: DataPoint[];
  importData?: DataPoint[];
  xAxisLabel: string;
  yAxisLabel: string;
  tooltip?: boolean;
  xToolTipLabel?: string;
  yToolTipLabel?: string;
}

export default (props: ChartProps) => {
  const { width: resizeWidth, height: resizeHeight, ref: resizeRef } = useResizeDetector();
  const divRef = useRef(null);
  const {
    title,
    data,
    xAxisLabel,
    yAxisLabel,
    tooltip,
    xToolTipLabel,
    yToolTipLabel,
    importData,
  } = props;

  const dataNest = Array.from(d3.group(data, d => d.id), ([key, value]) => ({ key, value }));

  useEffect(() => {
    if (!divRef.current) return;

    redrawGraph();
  }, [resizeHeight, resizeWidth, importData, data]);

  // Function to get the Min and Max for X and Y axis based on chart data and import data.
  const GetXAndYRanges = () => {
    // Get Min and Max x and y ranges
    const xValues: number[] = [];
    const yValues: number[] = [];
    if (data) {
      data.forEach((d) => {
        xValues.push(d.x);
        yValues.push(d.y);
      });
    }

    if (importData && importData.length > 0) {
      importData.forEach((d) => {
        xValues.push(d.x);
        yValues.push(d.y);
      });
    }
    return { xValues, yValues };
  };

  const redrawGraph = () => {
    const width = resizeWidth || 100;
    // eslint-disable-next-line no-nested-ternary
    const legendSize = dataNest.length > 6 ? 30 : dataNest.length >= 4 ? 15 : 0;
    const height = (resizeHeight || 100) - legendSize;
    const margin = { top: 20, right: 20, bottom: 55, left: 60 };
    const div = d3.select(divRef.current);
    div.selectAll('.main-svg').remove();
    div
      .style('width', '100%')
      .style('height', '100%');

    const { xValues, yValues } = GetXAndYRanges(); // Get Chart x-axis, and y-axis ranges
    const xScale = d3
      .scaleLinear()
      .domain([Math.min(...xValues) || 0, Math.max(...xValues) || 100])
      .range([0, width - margin.left - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([Math.max(...yValues) || 100, Math.min(...yValues) || 0])
      .range([0, height - margin.top - margin.bottom]);

    const line = d3
      .line<DataPoint>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y));

    const svg = div.append('svg')
      .attr('class', 'main-svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Draw x axis and label
    svg
      .append('g')
      .attr('transform', `translate(0,${height - margin.top - margin.bottom})`)
      .call(d3.axisBottom(xScale));
    svg
      .append('text')
      .attr('transform', `translate(${(width - margin.left - margin.right) / 2},${height - margin.bottom + 15})`)
      .style('fill', '#FFF')
      .style('text-anchor', 'middle')
      .text(xAxisLabel);

    // Draw y axis and label
    svg.append('g').call(d3.axisLeft(yScale));
    svg
      .append('text')
      .attr('transform', `rotate(-90) translate(-${(height - margin.bottom) / 2}, ${-margin.left + 10})`)
      .style('fill', '#FFF')
      .style('text-anchor', 'middle')
      .text(yAxisLabel);

    // Draw title
    if (title) {
      svg
        .append('text')
        .attr('transform', `translate(${(width - margin.left - margin.right) / 2}, ${-margin.top / 2})`)
        .style('text-anchor', 'middle')
        .style('fill', '#FFF')
        .text(title);
    }

    // Draw import data as dots
    if (importData && importData.length > 0) {
      svg.selectAll('dot')
        .data(importData)
        .enter().append('circle')
        .attr('stroke', '#FFF')
        .attr('r', 2.5)
        .attr('cx', d => xScale(d.x))
        .attr('cy', d => yScale(d.y))
        .attr('fill', 'none');

      // Add import data legend
      const importLegend = svg.append('g')
        .attr('stroke', '#FFF')
        .attr('transform', `translate(${width - 50}, -10)`);

      importLegend.append('circle')
        .attr('r', 2.5)
        .attr('cx', 2)
        .attr('cy', 2)
        .attr('fill', 'none');

      importLegend.append('text')
        .attr('transform', 'translate(10, 5)')
        .attr('stroke', '#FFF')
        .style('font-size', '10px')
        .text('Import');
    }

    // Draw the line
    svg.selectAll('path.line')
      .data(dataNest)
      .join('path')
      .attr('class', 'line')
      .attr('fill', 'none')
      .style('stroke', d => d.value[0].color)
      .attr('d', d => line(d.value));

    // Draw guide lines tracing mouse
    const focus = svg.append('g')
      .attr('class', 'focus')
      .style('display', 'none');

    focus.append('line')
      .attr('class', 'x-hover-line hover-line')
      .attr('stroke', '#FFF')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '3 3')
      .attr('y1', 0)
      .attr('y2', height);

    focus.append('line')
      .attr('class', 'y-hover-line hover-line')
      .attr('stroke', '#FFF')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '3 3')
      .attr('x1', 0)
      .attr('x2', 0);

    focus.append('circle')
      .attr('r', 3)
      .attr('fill', 'silver');

    // text
    const textMargin = { x: 15, y: '0.5em' };

    if (tooltip) {
      focus.append('g')
        .attr('class', 'floating-text')
        .append('text')
        .attr('x', textMargin.x)
        .attr('dy', '.5em')
        .style('fill', '#FFF');
    }

    // initialize legend
    // TODO if/when there are more than three data sources, this needs to be adjusted to have room
    const legend = svg.append('g')
      .attr('class', 'legend')
      .selectAll('g.legend')
      .data(dataNest)
      .enter()
      .append('g')
      .attr('class', 'legendItem')
      .attr('transform', (d, i) => {
        // Calculate column number
        const colNum = i % 3;

        // Calculate horizontal position based on column number
        const itemWidth = 30;
        const horz = ((width - margin.left - margin.right) / 3) * colNum - (itemWidth / 2);
        const distFromChart = 25;
        // Calculate vertical position based on row number
        const rowNumber = Math.ceil((i + 1) / 3);
        const vert = (height - margin.bottom + (rowNumber - 1) * 16) + distFromChart;

        return `translate(${horz},${vert})`;
      });

    // clip path for legend text
    const mLeft = Number(get(margin, 'left', margin));
    const mRight = Number(get(margin, 'right', margin));
    svg.append('clipPath')
      .attr('id', 'clipLegendText')
      .append('rect')
      .attr('y', -10)
      .attr('width', ((width - mLeft - mRight) / 3))
      .attr('height', 20);

    legend.append('circle').attr('cx', 0).attr('cy', 0).attr('r', 6)
      .style('fill', d => d.value[0].color);

    legend.append('text').attr('x', 15).attr('y', 0)
      .attr('fill', d => d.value[0].color)
      .text(d => d.value[0].name)
      .attr('clip-path', 'url(#clipLegendText)')
      .style('font-size', '10px')
      .attr('alignment-baseline', 'middle');

    const xAxisYLoc = yScale(Math.max(...yValues) || 0);

    // finds two closest points that bound the x arg along the x axis
    const findClosestXBoundingDataPoints = (x: number, y: number): { closestIndex: number, neighborIndex: number } | null => {
      const isBetween = (num1: number, num2: number, numToCheck: number) => {
        const low = Math.min(num1, num2);
        const high = Math.max(num1, num2);
        return (numToCheck >= low && numToCheck <= high);
      };

      let closestIndex: number | null = null;
      let neighborIndex: number | null = null;
      let minDist = Number.MAX_VALUE;

      // Include Imported data if exists
      let datapoints: DataPoint[] = data;
      if (importData && importData.length > 0) {
        datapoints = [...data, ...importData];
      }

      for (let i = 0; i < datapoints.length - 1; i++) {
        if (!isBetween(datapoints[i].x, datapoints[i + 1].x, x)) continue;

        const xDist1 = datapoints[i].x - x;
        const yDist1 = datapoints[i].y - y;
        const dist1 = Math.sqrt(xDist1 * xDist1 + yDist1 * yDist1);

        const xDist2 = datapoints[i + 1].x - x;
        const yDist2 = datapoints[i + 1].y - y;
        const dist2 = Math.sqrt(xDist2 * xDist2 + yDist2 * yDist2);

        if (dist1 < minDist) {
          minDist = dist1;
          closestIndex = i;
          neighborIndex = i + 1;
        }

        if (dist2 < minDist) {
          minDist = dist2;
          closestIndex = i + 1;
          neighborIndex = i;
        }
      }

      if (closestIndex === null || neighborIndex === null) return null;

      return { closestIndex, neighborIndex };
    };

    const interpolateForY = (index1: number, index2: number, x: number) => {
      // Include Imported data if exists
      let datapoints: DataPoint[] = data;
      if (importData && importData.length > 0) {
        datapoints = [...data, ...importData];
      }
      const totalXDist = Math.abs(datapoints[index1].x - datapoints[index2].x);
      const lowIndex = datapoints[index1].x < datapoints[index2].x ? index1 : index2;
      const highIndex = datapoints[index1].x >= datapoints[index2].x ? index1 : index2;

      const xRatio = (x - datapoints[lowIndex].x) / totalXDist;
      const yDiff = datapoints[highIndex].y - datapoints[lowIndex].y;
      return datapoints[lowIndex].y + xRatio * yDiff;
    };

    const onMouseMove = (event: MouseEvent) => {
      const x = xScale.invert(d3.pointer(event)[0]);
      const y = yScale.invert(d3.pointer(event)[1]);

      const xPos = xScale(x);

      const res = findClosestXBoundingDataPoints(x, y);
      if (res === null) return;

      const yValOnChart = interpolateForY(res.closestIndex, res.neighborIndex, x);
      const yPos = yScale(yValOnChart);

      focus.select('line.x-hover-line').attr('y2', xAxisYLoc - yPos);
      focus.select('line.y-hover-line').attr('x2', -xPos);
      const focusX = xPos + margin.left;
      const focusY = yPos + margin.top;

      if (tooltip) {
        const textGroupSelection = focus.select('g.floating-text');
        textGroupSelection
          .select('text')
          .text(`${xToolTipLabel || 'x'}: ${x.toFixed(2)}`)
          .append('tspan')
          .attr('x', textMargin.x)
          .attr('dy', 20)
          .text(`${yToolTipLabel || 'y'}: ${yValOnChart.toFixed(2)}`);

        const textGroupNode = textGroupSelection.node() as SVGGraphicsElement;
        const rect = textGroupNode.getBoundingClientRect();
        const svgRect = svg.node()?.getBoundingClientRect();
        if (rect && svgRect) {
          const groupWidth = rect.width;
          const groupHeight = rect.height;

          if (focusX + textMargin.x + groupWidth > width) textGroupSelection.attr('transform', `translate(-${groupWidth + textMargin.x * 2}, 0)`);
          else textGroupSelection.attr('transform', null);

          if (focusY + groupHeight > height) textGroupSelection.attr('y', yPos - groupHeight - 15);
          else textGroupSelection.attr('y', 15);
        }
      }

      focus.attr('transform', `translate(${xPos}, ${yPos})`);
    };

    const onTouchEvent = (event: TouchEvent) => {
      if (event.type === 'touchmove') event.preventDefault();   // don't preventDefault for touchstart. Touches won't be responsive.

      const touch = event.touches[0];
      const svgRect = svg.node()?.getBoundingClientRect();
      if (!svgRect) return;

      const clientX = touch.clientX - svgRect.x - margin.left;
      const clientY = touch.clientY - svgRect.y - margin.top;

      if (clientX < 0 || clientX > width) return;
      if (clientY < 0 || clientY > height) return;

      const mouseEvent = new MouseEvent('mousemove', {
        clientX,
        clientY,
      });
      onMouseMove(mouseEvent);
    };

    svg.append('rect')
      .attr('class', 'overlay')
      .attr('width', width - margin.left)
      .attr('height', height - margin.bottom)
      .style('fill-opacity', 0)
      .on('mouseover', () => focus.style('display', null))
      .on('mouseout', () => focus.style('display', 'none'))
      .on('mousemove', onMouseMove)
      .on('touchstart', onTouchEvent)
      .on('touchmove', onTouchEvent);
  };

  return (
    <div style={{ width: '100%', height: '100%' }} ref={resizeRef} onContextMenu={(e) => e.preventDefault()}>
      <div ref={divRef} />
    </div>
  );
};
