import React from 'react';
import QueryDocs from './QueryDocs';
import MutationDocs from './MutationDocs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

import styles from './index.module.css';

const getGraphQLEndpoint = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}/api/graphql`;
};

const DocsPage: React.FC = () => {
  const graphqlEndpoint = getGraphQLEndpoint();

  return (
    <div>
      <h1>API Documentation</h1>
      <p>
        You can query a GraphQL server directly with an HTTP request. GraphQL provides a standard HTTP endpoing called the GraphQL API endpoint.
        You can send an HTTP POST request to this endpoint to execute GraphQL queries and mutations.
      </p>
      <p>Use the following endpoint to access the GraphQL API:</p>
      <a href={graphqlEndpoint} target="_blank" rel="noopener noreferrer">
        {graphqlEndpoint}
      </a>

      <section>
        <h2>Using the GraphQL Endpoint with a POST Request</h2>
        <p>
          To make a request to the GraphQL server, you'll need to include the GraphQL query or mutation in the request payload.
          The payload should follow the GraphQL request structure, typically sent as JSON. Here's an example of an HTTP request to a GraphQL server:
        </p>
        <SyntaxHighlighter language="json" style={tomorrow} className={styles.codeBlock}>
          {`
POST ${graphqlEndpoint}
Content-Type: application/json

{
  "query": "your GraphQL query or mutation goes here",
  "variables": {}
}
          `}
        </SyntaxHighlighter>
        <p>
          In this example, you specify the GraphQL query or mutation in the "query" field. If your query or mutation requires variables, you can pass them in the "variables" field as a JSON object.
        </p>
      </section>

      <section>
        <h2>Queries</h2>

        <QueryDocs
          description="Fetches documents based on the specified type and ID or name."
          returnType="[Document!]!"
          args={[
            { name: 'type', type: 'String!', description: 'The type of document to fetch. Supported types: driver, lap_time_data, part, vehicle, sim.' },
            { name: 'id', type: 'Int', description: 'The ID of the document to fetch. Required for all document types except part documents' },
            { name: 'name', type: 'String', description: 'The name of the document to fetch. Required for part documents.' },
          ]}
          examples={[
            {
              type: 'Driver',
              query: `
query($type: String, $id: Int) {
  query(type: $type, id: $id) {
    _id
    meta {
      type
      desc
      track
      status
      owner
    }
    driver
  }
}
              `,
              variables: `{
  "type": "driver",
  "id": 1
}`,
            },
            {
              type: 'Lap Time Data',
              query: `
query($type: String, $id: Int) {
  query(type: $type, id: $id) {
    _id
    meta {
      type
      desc
      name
      status
      owner
    }
    lap_time_data
  }
}
              `,
              variables: `{
  "type": "lap_time_data",
  "id": 1
}`,
            },
            {
              type: 'Part',
              query: `
query($type: String, $name: String) {
  query(type: $type, name: $name) {
    _id
    meta {
      type
      name
      desc
      owner
    }
    part
  }
}
              `,
              variables: `{
  "type": "part",
  "name": "shock"
}`,
            },
            {
              type: 'Vehicle',
              query: `
query($type: String, $id: Int) {
  query(type: $type, id: $id) {
    _id
    meta {
      type
      name
      spec
      year
      event
      owner
    }
    vehicle
  }
}
              `,
              variables: `{
  "type": "vehicle",
  "id": 1
}`,
            },
            {
              type: 'Sim',
              query: `
query($type: String, $id: Int) {
  query(type: $type, id: $id) {
    _id
    meta {
      type
      name
      desc
      spec
      owner
    }
    sim
  }
}
              `,
              variables: `{
  "type": "sim",
  "id": 1
}`,
            },
          ]}
        />
      </section>

      <section>
        <h2>Mutations</h2>

        <MutationDocs
          mutationName="submit"
          description="Submit documents of various types."
          returnType="String"
          args={[
            { name: '_id', type: 'String', description: 'The (optional) ID of the document to submit and update.' },
            { name: 'driver', type: 'JSONObject', description: 'Driver document data.' },
            { name: 'lap_time_data', type: 'JSONObject', description: 'Lap time data document.' },
            { name: 'part', type: 'JSONObject', description: 'Part document data.' },
            { name: 'vehicle', type: 'JSONObject', description: 'Vehicle document data.' },
            { name: 'sim', type: 'JSONObject', description: 'Sim document data.' },
            { name: 'meta', type: 'DocumentMetaInput!', description: 'Metadata for the document.' },
          ]}
          examples={[
            {
              type: 'Driver',
              additionalInfo: '{type} property must be "trd" or "avl"',
              mutation: `
mutation Submit($meta: DocumentMetaInput!, $driver: JSONObject) {
  submit(meta: $meta, driver: $driver)
}
              `,
              variables: `{
  "meta": {
    "type": "trd",
    "desc": "Driver Description",
    "track": "Track Name"
  },
  "driver": { ... }
}`,
            },
            {
              type: 'Lap Time Data',
              mutation: `
mutation Submit($meta: DocumentMetaInput!, $lap_time_data: JSONObject) {
  submit(meta: $meta, lap_time_data: $lap_time_data)
}
              `,
              variables: `{
  "meta": {
    "desc": "Lap Time Data Description",
    "name": "Lap Time Data Name"
  },
  "lap_time_data": { ... }
}`,
            },
            {
              type: 'Part',
              mutation: `
mutation Submit($meta: DocumentMetaInput!, $part: JSONObject) {
  submit(meta: $meta, part: $part)
}
              `,
              variables: `{
  "meta": {
    "name": "Part Type Name",
    "desc": "Part Description",
    "spec": "Part Spec"
  },
  "part": { ... }
}`,
            },
            {
              type: 'Vehicle',
              mutation: `
mutation Submit($meta: DocumentMetaInput!, $vehicle: JSONObject) {
  submit(meta: $meta, vehicle: $vehicle)
}
              `,
              variables: `{
  "meta": {
    "name": "Vehicle Name",
    "desc": "Vehicle Description",
    "spec": "Vehicle Spec",
    "series": "Vehicle Series",
    "year": "Vehicle Year",
    "event": "Vehicle Event",
    "root_id": 1
  },
  "vehicle": { ... }
}`,
            },
            {
              type: 'Sim',
              additionalInfo: '{simType} must be one of KINEMATICS ELAP RIDEMODEL SETUP SIMULATOR EIGHTPOST',
              mutation: `
mutation Submit($meta: DocumentMetaInput!, $sim: JSONObject) {
  submit(meta: $meta, sim: $sim)
}
              `,
              variables: `{
  "meta": {
    "name": "Sim Name",
    "spec": "Vehicle Spec",
    "simType": KINEMATICS
  },
  "sim": { ... }
}`,
            },
          ]}
        />
      </section>
    </div>
  );
};

export default DocsPage;
