import { Button, Dialog, DialogBody, DialogFooter, InputGroup, Intent } from '@blueprintjs/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { useAlert } from 'components/Alert';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import { format } from 'date-fns';
import { Sweep, useCloneSweepMutation, useDeleteSweepMutation, useSweepsLazyQuery } from 'graphql/generated/graphql';
import { cloneDeep, debounce, find, keyBy, mapValues } from 'lodash';
import AddSweepDialog from 'pages/SweepsSummary/AddSweepDialog';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { selectDarkMode } from 'reducers/ui';
import AppToaster from 'helpers/toaster';
import { seriesItems, teamSelectItems, organizationSelectItems } from '../../constants';
import Select from 'components/Select';

interface Props {
  isAddSweepDialogOpen: boolean;
  onAddSweepDialogClose: () => void;
}

export default (props: Props) => {
  const { isAddSweepDialogOpen, onAddSweepDialogClose } = props;
  const darkMode = useSelector(selectDarkMode);
  const [cloneSource, setCloneSource] = useState<Sweep>();
  const [tableData, setTableData] = useState<Sweep[]>([]);
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const cloneName = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const [cloneSweep] = useCloneSweepMutation();
  const [deleteSweep] = useDeleteSweepMutation();

  const [getSweeps, { refetch: refetchSweeps }] = useSweepsLazyQuery({
    onCompleted: data => setTableData(data.sweeps.rows as Sweep[]),
    fetchPolicy: 'cache-and-network',
  });

  const alert = useAlert();
  const navigate = useNavigate();

  const handleRefetchSweeps = useCallback(() => {
    refetchSweeps();
  }, [refetchSweeps]);

  const columnHelper = createColumnHelper<Sweep>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('description', {
      header: 'Description',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Sweep>[];

  const rowActions: RowActions<Sweep> = [{
    label: 'Edit',
    value: row => {
      navigate(`/sims/sweeps/${row.original.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      const sweepId = row.original.id;
      if (!sweepId) return;
      alert.showAlert(`Delete sweep "${row.original.name}?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteSweep({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Sweep successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetchSweeps();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting sweep: ${e.message}`,
            });
          },
        });
      });
    },
  }];

  const handleClone = () => {
    if (!cloneSource) return;
    cloneSweep({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
        orgName: cloneSource.organization_name,
        orgId: cloneSource.organization_id,
        teamName: cloneSource.team_name,
        teamId: cloneSource.team_id,
        series: cloneSource.series,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned sweep',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone sweep: ${e.message}`,
        });
      },
      refetchQueries: ['Sweeps'],
    });
    setCloneModalOpen(false);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getSweeps({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectItem = (item: any, name: string) => {
    const clone = cloneDeep(cloneSource);

    if (clone) {
      if (name === 'series') {
        clone.series = item.value;
      }

      if (name === 'org') {
        clone.organization_name = item.value;
        clone.organization_id = item.id;

        // Reset team selections if org changes.
        const findTeam = find(teams, team => team.name === cloneSource?.team_name);
        if (findTeam && findTeam.organization.name !== item.value) {
          clone.team_name = null;
          clone.team_id = null;
        }
      }

      if (name === 'team') {
        clone.team_name = item.value;
        clone.team_id = item.id;
      }

      setCloneSource(clone);
    }
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, 200);

  return (
    <div>
      <Table
        id="sweeps-summary"
        columns={columns}
        data={tableData}
        rowActions={rowActions}
        totalRowCount={0}
        enableHiding
        enablePagination
        manualFiltering
        manualPagination
        manualSorting
        persistColumnVisibility
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <div>
            <div style={{ paddingBottom: '5px' }}>Name</div>
            <InputGroup
              placeholder="Enter new sweep name"
              inputRef={cloneName}
              defaultValue={`${cloneSource?.name} CLONE`}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Organization</div>
            <Select
              initialItem={{ label: cloneSource?.organization_name || 'None', value: cloneSource?.organization_name || null }}
              items={organizationSelectItems(organizations)}
              noSelectionText="Organization"
              onChange={item => setSelectItem(item, 'org')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Team</div>
            <Select
              initialItem={{ label: cloneSource?.team_name || 'None', value: cloneSource?.team_name || null }}
              items={teamSelectItems(teams, cloneSource?.organization_name)}
              noSelectionText="Team"
              onChange={item => setSelectItem(item, 'team')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Series</div>
            <Select
              initialItem={seriesItems.find(i => cloneSource?.series === i.value)}
              items={seriesItems}
              noSelectionText="Series"
              onChange={item => setSelectItem(item, 'series')}
            />
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
      <AddSweepDialog
        isOpen={isAddSweepDialogOpen}
        onClose={onAddSweepDialogClose}
        handleRefetchSweeps={handleRefetchSweeps}
      />
    </div>
  );
};
