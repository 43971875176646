import { useEffect, useState, useCallback } from 'react';
import {
  Button,
  FormGroup,
  Intent,
  Checkbox,
  Alignment,
  EditableText,
  Tree,
  TreeNodeInfo,
} from '@blueprintjs/core';
import { useForm, useWatch, Controller } from 'react-hook-form';
import classNames from 'classnames';
import {
  unstable_useBlocker,      // eslint-disable-line camelcase
  unstable_BlockerFunction, // eslint-disable-line camelcase
} from 'react-router-dom';

import {
  Sim,
  useUpdateSimMutation,
  UpdateSimInput,
} from 'graphql/generated/graphql';
import Accordion from 'components/Accordion';
import RHFNumericInput from 'components/RHFInputs/NumericInput';
import RHFTextInput from 'components/RHFInputs/TextInput';
import styles from '../index.module.css';
import AppToaster from 'helpers/toaster';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import { KinematicsSim } from '../types';

interface Props {
  sim: Sim;
}

const nodes: TreeNodeInfo[] = [
  {
    id: 0,
    hasCaret: false,
    label: 'Config',
  },
  {
    id: 1,
    hasCaret: false,
    label: 'Output',
  },
];

export default (props: Props) => {
  const [negativeRollAngle, setNegativeRollAngle] = useState(0);
  const [negativeSteerWheelAngle, setNegativeSteerWheelAngle] = useState(0);
  const defaultKinematicsSim: KinematicsSim = props.sim;
  const form = useForm<Partial<KinematicsSim>>({
    defaultValues: {
      ...defaultKinematicsSim,
    },
  });
  const { control, setValue, formState: { isDirty } } = form;

  const [updateSim] = useUpdateSimMutation();

  const rollAngle = useWatch({
    control,
    name: 'data.roll_angle',
  });

  const steerWheelAngle = useWatch({
    control,
    name: 'data.steer_wheel_angle',
  });

  useEffect(() => {
    if (rollAngle) {
      setNegativeRollAngle(rollAngle * -1);
    }
  }, [rollAngle]);

  useEffect(() => {
    if (steerWheelAngle) {
      setNegativeSteerWheelAngle(steerWheelAngle * -1);
    }
  }, [steerWheelAngle]);

  useEffect(() => {
    if (isDirty) {
      addWarningListener();
    } else {
      removeWarningListener();
    }
  }, [isDirty]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: unstable_BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  unstable_useBlocker(blockerFunc);

  const handleNodeClick = useCallback(
    (node: TreeNodeInfo) => {
      const sectionElement = document.getElementById(`section-${node.id}`);

      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [],
  );

  const onSetupNameChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });
  };

  const onSubmit = (input: Partial<KinematicsSim>) => {
    // eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars
    const { drive_file, sim_states, driver, lap_time_data, eight_post,
      ...kinematicsSim } = input;

    const updatedSimInput: UpdateSimInput = {
      ...kinematicsSim as KinematicsSim,
    };
    updateSim({
      variables: {
        input: {
          ...updatedSimInput,
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Sim successfully updated',
        });

        form.reset({ ...input });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating Sim: ${e.message}`,
        });
      },
    });
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className={styles.titleBar}>
        <div className={styles.titleLabel}>Kinematics</div>
        <EditableText
          className={styles.titleValue}
          defaultValue={props.sim.name}
          onChange={value => onSetupNameChange(value)}
          placeholder="Kinematics"
        />
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.nav}>
          <Button
            className={styles.saveButton}
            intent={Intent.PRIMARY}
            type="submit"
            text="Save"
            fill
            disabled={!isDirty}
          />
          <div className={styles.sideNavContainer}>
            <Tree
              contents={nodes}
              onNodeClick={handleNodeClick}
            />
          </div>
        </div>
        <div className={styles.simContainer}>
          <Accordion
            className={styles.itemContainer}
            id="section-0"
            initialOpen
            key="config"
            title="Config"
            buttonProps={{
              className: styles.accordionHeader,
            }}
          >
            <div className={styles.sectionContainer}>
              <div className={styles.inputRow}>
                <FormGroup
                  className={classNames(styles.formGroup, styles.flex)}
                  contentClassName={styles.simInput}
                  label="Max Wheel Travel"
                  inline
                >
                  <RHFNumericInput
                    controllerProps={{
                      control,
                      name: 'data.max_wheel_travel',
                    }}
                    inputProps={{
                      fill: true,
                    }}
                  />
                </FormGroup>
              </div>
              <div className={styles.inputRow}>
                <FormGroup
                  className={classNames(styles.formGroup, styles.flex)}
                  contentClassName={styles.simInput}
                  label="Roll Angle"
                  inline
                >
                  <div className={styles.gridInputs}>
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.negative_roll_angle',
                      }}
                      inputProps={{
                        disabled: true,
                        fill: true,
                        value: negativeRollAngle,
                      }}
                    />
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.roll_angle',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className={styles.inputRow}>
                <FormGroup
                  className={classNames(styles.formGroup, styles.flex)}
                  contentClassName={styles.simInput}
                  label="Steer Wheel Angle"
                  inline
                >
                  <div className={styles.gridInputs}>
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.negative_steer_wheel_angle',
                      }}
                      inputProps={{
                        disabled: true,
                        fill: true,
                        value: negativeSteerWheelAngle,
                      }}
                    />
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.steer_wheel_angle',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
          </Accordion>
          <Accordion
            className={styles.itemContainer}
            id="section-1"
            initialOpen
            key="output"
            title="Output"
            buttonProps={{
              className: styles.accordionHeader,
            }}
          >
            <div className={styles.sectionContainer}>
              <div className={styles.inputRow}>
                <FormGroup
                  className={classNames(styles.formGroup, styles.flex)}
                  contentClassName={styles.simInput}
                  label="Output Directory"
                  inline
                >
                  <RHFTextInput
                    controllerProps={{
                      control,
                      name: 'output_directory',
                    }}
                    inputProps={{
                      fill: true,
                    }}
                  />
                </FormGroup>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.outputCheckboxContainer}>
                  <div className={styles.outputCheckboxName}>Motec</div>
                  <Controller
                    control={control}
                    name="export_motec"
                    render={({ field }) => (
                      <Checkbox
                        label="Export"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="download_motec"
                    render={({ field }) => (
                      <Checkbox
                        label="Download"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.outputCheckboxContainer}>
                  <div className={styles.outputCheckboxName}>JSON</div>
                  <Controller
                    control={control}
                    name="export_json"
                    render={({ field }) => (
                      <Checkbox
                        label="Export"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="download_json"
                    render={({ field }) => (
                      <Checkbox
                        label="Download"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </form>
  );
};
