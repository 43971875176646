import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, InputGroup, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import Chart from '../Chart';
import FilePicker from 'components/Common/FilePicker';
import PartSelector from 'components/SUITForm/PartSelector';
import SavePartModal from 'components/SavePartModal';
import InputCheckbox from 'components/InputCheckbox';
import styles from './index.module.css';
import { ImportPartResult, Part, Scalars, Spec, useImportPartFileMutation, useGetSimResultSubscription, useRequestPartFitMutation } from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import _ from 'lodash';
import { readCSVFile } from '../../../helpers/PartImport/Parts';

export interface ChartDataItem {
  name: string;
  id: number,
  color: string,
  x: number;
  y: number;
}

export interface PartInput {
  partId: number,
  setupId: number,
  pos: string,
  partType: string,
  partFormat: string,
  description: string,
  spec: Spec,
  data: Scalars['JSONObject'],
}

interface ShockChartProps {
  position: string;
  chartData: ChartDataItem[];
  selectedPart?: Part;
  setupId: number,
  parts: Part[];
  onChangeHandler?: (path: string, value: number, pos: string, newPart?: Part | undefined) => void;
  onUpdatePlotData?: (updatePart: PartInput) => void;
  path: string;
  partType: string;
  partConfigId?: number,
  spec: Spec,
  readOnly: boolean;
}

interface DataPoint {
  displayName?: string,
  name: string;
  id: number,
  color: string,
  x: number;
  y: number;
}

interface ProcShockObj {
  objectType: string;
  procData: number[][];
  runConstants?: Array<{ fieldName: string; value: number }>;
  fileName: string;
}

interface FormattedResult {
  data: number[][];
  success: boolean;
  type: string;
  clicks?: string;
  jet?: string;
}

interface FormattedCSVResult {
  data: number[][];
  success: boolean;
  type: string;
}

interface CoeffDataType {
  JetType: string,
  CBleed: number,
  RBleed: number,
  CBlowOff: number,
  RBlowOff: number,
  CShimStiffness: number,
  RShimStiffness: number,
  CShimCurvature: number,
  RShimCurvature: number,
  AdjusterClicks: number,
  AdvancedParam: Scalars,
}

export default (props: ShockChartProps) => {
  const [isSavePartDialogOpen, setIsSavePartDialogOpen] = useState(false);
  // Baseline part type
  const [partType, setPartType] = useState(props.partType);
  const [importedFileName, setImportedFileName] = useState('');
  const [customFitPart, setCustomFitPart] = useState<PartInput []>([]);
  const [chartData, setChartData] = useState<ChartDataItem[]>(props.chartData || []);
  const [canCommit, setCanCommit] = useState(true);

  // Coeff checkbox states
  const [useCBleed, setUseCBleed] = useState(false);
  const [useRBleed, setUseRBleed] = useState(false);
  const [useCBlowOff, setUseCBlowOff] = useState(false);
  const [useRBlowOff, setUseRBlowOff] = useState(false);
  const [useCStiffnes, setUseCStiffnes] = useState(false);
  const [useRStiffnes, setUseRStiffnes] = useState(false);
  const [useCCurvature, setUseCCurvature] = useState(false);
  const [useRCurvature, setUseRCurvature] = useState(false);

  // Coeff field states
  const [jetType, setJetType] = useState(props.selectedPart?.data.JetType || 'Open Jet');
  const [cBleed, setCBleed] = useState(props.selectedPart?.data.CBleed ||  0);
  const [rBleed, setRBleed] = useState(props.selectedPart?.data.RBleed || 0);
  const [cBlowOff, setCBlowOff] = useState(props.selectedPart?.data.CBlowOff || 0);
  const [rBlowOff, setRBlowOff] = useState(props.selectedPart?.data.RBlowOff || 0);
  const [cShimStiffness, setCShimStiffness] = useState(props.selectedPart?.data.CShimStiffness || 0);
  const [rShimStiffness, setRShimStiffness] = useState(props.selectedPart?.data.RShimStiffness || 0);
  const [cShimCurvature, setCShimCurvature] = useState(props.selectedPart?.data.CShimCurvature || 0);
  const [rShimCurvature, setRShimCurvature] = useState(props.selectedPart?.data.RShimCurvature || 0);
  const [adjusterClicks, setAdjusterClicks] = useState(props.selectedPart?.data.AdjusterClicks || 0);
  const [advancedParam, setAdvancedParam] = useState(props.selectedPart?.data.AdvancedParam || {});

  // VRP field states
  const [compression_clicks_blowoff, setCompression_clicks_blowoff] = useState(props.selectedPart?.data.base_model?.compression_clicks_blowoff || 0);// eslint-disable-line camelcase
  const [compression_clicks_high_speed, setCompression_clicks_high_speed] = useState(props.selectedPart?.data.base_model?.compression_clicks_high_speed || 0);// eslint-disable-line camelcase
  const [rebound_clicks_high_speed, setRebound_clicks_high_speed] = useState(props.selectedPart?.data.base_model?.rebound_clicks_high_speed || 0);// eslint-disable-line camelcase
  const [compression_clicks_low_speed, setCompression_clicks_low_speed] = useState(props.selectedPart?.data.base_model?.compression_clicks_low_speed || 0);// eslint-disable-line camelcase
  const [rebound_clicks_low_speed, setRebound_clicks_low_speed] = useState(props.selectedPart?.data.base_model?.rebound_clicks_low_speed || 0);// eslint-disable-line camelcase
  const [compression_valve, setCompression_valve] = useState(props.selectedPart?.data.base_model?.compression_valve || '');// eslint-disable-line camelcase
  const [rebound_valve, setRebound_valve] = useState(props.selectedPart?.data.base_model?.rebound_valve || '');// eslint-disable-line camelcase
  const [compression_valve_spring, setCompression_valve_spring] = useState(props.selectedPart?.data.base_model?.compression_valve_spring || '');// eslint-disable-line camelcase
  const [rebound_valve_spring, setRebound_valve_spring] = useState(props.selectedPart?.data.base_model?.rebound_valve_spring || '');// eslint-disable-line camelcase
  const [importPartFile] = useImportPartFileMutation();
  const [importData, setImportData] = useState<DataPoint[]>();
  const { data: shockFitData } = useGetSimResultSubscription();
  const xAxisLabel = 'Velocity (in/s)';
  const yAxisLabel = 'Load (lbf)';
  const xToolTipLabel = 'Velocity';
  const yToolTipLabel = 'Load';
  const radioGroupName = `${props.position}.radioGroup`;

  // Query for Part Fit api request
  const [requestPartFit] = useRequestPartFitMutation();

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { id, name, value } = e.target as HTMLInputElement;

    switch (id) {
      case 'CBleed':
        setCBleed(Number(value));
        break;
      case 'RBleed':
        setRBleed(Number(value));
        break;
      case 'CBlowOff':
        setCBlowOff(Number(value));
        break;
      case 'RBlowOff':
        setRBlowOff(Number(value));
        break;
      case 'CShimStiffness':
        setCShimStiffness(Number(value));
        break;
      case 'RShimStiffness':
        setRShimStiffness(Number(value));
        break;
      case 'CShimCurvature':
        setCShimCurvature(Number(value));
        break;
      case 'RShimCurvature':
        setRShimCurvature(Number(value));
        break;
      case 'AdjusterClicks':
        setAdjusterClicks(Number(value));
        break;
      case 'compression_clicks_blowoff':
        setCompression_clicks_blowoff(Number(value));
        break;
      case 'compression_clicks_high_speed':
        setCompression_clicks_high_speed(Number(value));
        break;
      case 'rebound_clicks_high_speed':
        setRebound_clicks_high_speed(Number(value));
        break;
      case 'compression_clicks_low_speed':
        setCompression_clicks_low_speed(Number(value));
        break;
      case 'rebound_clicks_low_speed':
        setRebound_clicks_low_speed(Number(value));
        break;
      case 'compression_valve':
        setCompression_valve(value);
        break;
      case 'rebound_valve':
        setRebound_valve(value);
        break;
      case 'compression_valve_spring':
        setCompression_valve_spring(value);
        break;
      case 'rebound_valve_spring':
        setRebound_valve_spring(value);
        break;
      default:
        switch (name) {
          case radioGroupName:
            setJetType(value);
            break;
          default:
            break;
        }
    }
  };

  const mapJetType = (jetTypeIn: string) => {
    let jetType = jetTypeIn;
    switch (jetTypeIn) {
      case 'Compression Jet':
        jetType = 'Comp Jet';
        break;
      case 'Rebound Jet':
        jetType = 'Reb Jet';
        break;
      default:
        break;
    }

    return jetType;
  };

  const GetDBPartData = () => {
    if (partType === 'coeff') {
      return {
        CBleed: Number(cBleed),
        RBleed: Number(rBleed),
        CBlowOff: Number(cBlowOff),
        RBlowOff: Number(rBlowOff),
        CShimCurvature: Number(cShimCurvature),
        RShimCurvature: Number(rShimCurvature),
        CShimStiffness: Number(cShimStiffness),
        RShimStiffness: Number(rShimStiffness),
        JetType: jetType,
        AdjusterClicks: Number(adjusterClicks),
        AdvancedParam: advancedParam,
        // Only include these properties if they exist
        ...(props.selectedPart?.data?.Compliance && { Compliance: props.selectedPart.data.Compliance }),
        ...(props.selectedPart?.data?.GasPressure && { GasPressure: props.selectedPart.data.GasPressure }),
        ...(props.selectedPart?.data?.hysteresis && { hysteresis: props.selectedPart.data.hysteresis }),
      };
    } else if (partType === 'vrp') {
      return {
        base_model: {
          model_type: 'vrp',
          compression_clicks_blowoff: Number(compression_clicks_blowoff), // eslint-disable-line camelcase
          compression_clicks_high_speed: Number(compression_clicks_high_speed), // eslint-disable-line camelcase
          rebound_clicks_high_speed: Number(rebound_clicks_high_speed), // eslint-disable-line camelcase
          compression_clicks_low_speed: Number(compression_clicks_low_speed), // eslint-disable-line camelcase
          rebound_clicks_low_speed: Number(rebound_clicks_low_speed), // eslint-disable-line camelcase
          compression_valve, // eslint-disable-line camelcase
          rebound_valve, // eslint-disable-line camelcase
          compression_valve_spring, // eslint-disable-line camelcase
          rebound_valve_spring, // eslint-disable-line camelcase
        },
        // Only include these properties if they exist
        ...(props.selectedPart?.data?.hysteresis && { hysteresis: props.selectedPart.data.hysteresis }),
      };
    }
    // Tabular Shocks, return shock array
    return {
      shock_data: getChartDataArray(importData),
    };
  };

  const getStatePartInput = () => {
    return {
      partId: props.selectedPart?.id,
      setupId: props.setupId,
      pos: props.position,
      partFormat: partType,
      description: props.selectedPart?.description,
      spec: props.selectedPart?.spec,
      data: GetDBPartData(),
    };
  };

  const setStateCoeffData = (coeffData: CoeffDataType) => {
    setJetType(coeffData.JetType);
    setCBleed(coeffData.CBleed);
    setRBleed(coeffData.RBleed);
    setCBlowOff(coeffData.CBlowOff);
    setRBlowOff(coeffData.RBlowOff);
    setCShimStiffness(coeffData.CShimStiffness);
    setRShimStiffness(coeffData.RShimStiffness);
    setCShimCurvature(coeffData.CShimCurvature);
    setRShimCurvature(coeffData.RShimCurvature);
    setAdjusterClicks(coeffData.AdjusterClicks);
    setAdvancedParam(coeffData.AdvancedParam);
  };

  // Get ChartDataItems
  const getChartDataItems = (partName: string, setupId: number, setupColor: string, forceData: number[][]) => {
    const fitArray: number [][] = forceData;

    const ChartDataItems = fitArray.map((item: number[]) => ({
      x: item[0],
      y: item[1],
      name: partName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  const ProcessShockFile = async (file: File): Promise<FormattedResult> => {
    // Set local state of imported file name
    setImportedFileName(file.name);

    if (_.endsWith(file.name.toUpperCase(), '.CSV')) {
      try {
        const { procData, objectType } = await readCSVFile(file);
        return formatCSVShockResults({ procData, objectType, fileName: file.name });
      } catch (error) {
        throw new Error('Failed to import CSV file.');
      }
    } else if (_.endsWith(file.name.toUpperCase(), '.PVP')) {
      try {
        const fileBinaryString = await new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result as string); // Explicitly cast to exclude null
          fileReader.onerror = reject;
          fileReader.readAsBinaryString(file);
        });

        // If after reading no error was thrown...and fileBinaryString is empty throw error
        if (!fileBinaryString) {
          throw new Error('Failed to import/read asr file.');
        }

        // Call the GraphQL mutation with the prepared arguments
        const response: ImportPartResult = await importPartFile({
          variables: {
            fileBuffer: fileBinaryString,
            fileName: file.name,
            pos: props.position,
            type: 'shock',
          },
        }) as ImportPartResult;

        // Handle the result of the mutation
        if (response.data.importPartFile.success) {
          // Process the successfully imported part file
          // eslint-disable-next-line camelcase
          const { clicks, jet } = response.data.importPartFile.data;

          // If clicks return, set local state for new part creation
          if (clicks) setAdjusterClicks(clicks);

          // If Jet Type return, set local state for new part creation
          if (jet) setJetType(mapJetType(jet));

          setPartType('coeff');

          return {
            ...{
              success: true,
              data: response.data.importPartFile.data.chart_data,
              type: response.data.importPartFile.type || 'defaultType',
            },
            ...(clicks && { clicks }),
            ...(jet && { jet }),
          };
        }
        return {
          success: false,
          data: response.data.importPartFile.data?.error,
          type: response.data.importPartFile.type || 'defaultType',
        };
      } catch (error) {
        throw new Error(`Import ASR Error: ${error}`);
      }
    }
    throw new Error('Selected file not supported for importing...');
  };

  const refreshPlotData = (event: React.FocusEvent<HTMLInputElement>) => {
    let hasChanged = false;
    const field = event.target.id;
    switch (field) {
      case 'compression_valve':
      case 'rebound_valve':
      case 'compression_valve_spring':
      case 'rebound_valve_spring':
        hasChanged = event.target.value !== props.selectedPart?.data[field];
        break;
      default:
        hasChanged = Number(event.target.value) !== props.selectedPart?.data[field];
        break;
    }

    if (props.onUpdatePlotData && hasChanged) {
      // Gather updated Coeff Data and raise event for plot fetching request.
      props.onUpdatePlotData(getStatePartInput() as PartInput);
    }
  };

  const isAbsoluteTabular = (data: number[][]): boolean => {
    if (data.length > 0) {
      return _.every(data, arr => arr[0] >= 0);
    }

    return false;
  };

  const getStateCoeffData = () => {
    return {
      coeff: {
        jetType,
        cBleed,
        rBleed,
        cBlowOff,
        rBlowOff,
        cShimStiffness,
        rShimStiffness,
        cShimCurvature,
        rShimCurvature,
        adjusterClicks,
        advancedParam,
      },
    };
  };

  const getChartDataArray = (dataPoints: DataPoint[] | undefined): number[][] => {
    if (!dataPoints) {
      return [];
    }

    return dataPoints.map(point => [point.x, point.y]);
  };

  const getStateHolds = () =>  {
    const holdArray: string[] = [];
    if (useCBleed) holdArray.push('CBleed');
    if (useRBleed) holdArray.push('RBleed');
    if (useCBlowOff) holdArray.push('CBlowOff');
    if (useRBlowOff) holdArray.push('RBlowOff');
    if (useCStiffnes) holdArray.push('CShimStiffness');
    if (useRStiffnes) holdArray.push('RShimStiffness');
    if (useCCurvature) holdArray.push('CShimCurvature');
    if (useRCurvature) holdArray.push('RShimCurvature');

    return holdArray;
  };

  const getFitPartData = () => {
    const importName = importedFileName || 'imported_file';
    const fitData: PartInput = {
      partId: 0,
      setupId: 0,
      pos: props.position,
      partType: 'shock',
      partFormat: partType,
      description: importName,  // Get Import ChartData file name
      spec: props.selectedPart?.spec || Spec.GEN7,
      data: {},
    };
    // If Coeff exist, set payload structure first
    if (partType === 'coeff') {
      fitData.data = getStateCoeffData();
    }

    fitData.data.chartData = getChartDataArray(importData); // Set Import ChartData
    fitData.data.hold = getStateHolds();
    return fitData;
  };

  const handleFitPart = () => {
    // Collect part data into an object and pass/raise  props.onFitClicked
    setCustomFitPart([getFitPartData()]);
    setCanCommit(true);
  };

  // Open save part dialog
  const handleCommitPart = () => {
    setIsSavePartDialogOpen(true);
  };

  // onSuccess close save part dialog
  const onCommittedPart = (newPart: Part) => {
    // Clear Import Data and imported file name
    setImportData(undefined);
    setImportedFileName('');
    // Fire onChangeHandler for newly created part.
    if (props.onChangeHandler) {
      props.onChangeHandler?.(props.path, newPart.id, props.position, newPart);
    }
  };

  const formatCSVShockResults = (result: ProcShockObj): FormattedCSVResult => {
    let importedCSVType = 'tabular';
    // If absolute, part will need to be fit and become coeff, else it's a tabular part, ready to commit to the db
    if (isAbsoluteTabular(result.procData)) { // Absolute value shock_data.x array means this parts final format will be coeff
      setPartType('coeff');
      setAdjusterClicks(0);
      setJetType('Comp Jet');
      importedCSVType = 'coeff';
    } else { // True value shock_data.x array means this parts final format will be tabular
      setPartType('tabular');
      // Update ChartData
      if (result.procData) {
        let updatedChartData: ChartDataItem[] = [...chartData];
        const foundChartDataItem: ChartDataItem[] = updatedChartData.filter(item => item.id === props.setupId);
        const color = foundChartDataItem.length > 0 && foundChartDataItem[0].color ? foundChartDataItem[0].color : 'red';

        updatedChartData = [...updatedChartData.filter(item => item.id !== props.setupId),
          ...applyImportedTrueTabularChartDataItems(`${result.fileName} - Tabular`, props.setupId, color, 'TempSetupName', result.procData)];

        setChartData(updatedChartData);
        // Clear Coeff data
        setStateCoeffData({} as CoeffDataType);
        setCanCommit(false);
      }
    }

    return {
      success: true,
      type: importedCSVType, // If Positive array,  else if
      data: result.procData,
    };
  };

  const updateStateImportData = (data: number[][]) => {
    const scatterDatasets: DataPoint[] = data.map(item => ({
      ...getImportCoords(item),
      name: 'import-data',
      displayName: 'Import Data',
      id: -1,
      color: 'white',
    }));
    setImportData(scatterDatasets);
  };

  // Gets ChartDataItems for tabular shocks that were true values, converted to absolute for visuals
  const applyImportedTrueTabularChartDataItems = (plotName: string, setupId: number, setupColor: string, setupName: string, shockData?: number[][]) => {
    const tabularArray = shockData || [];

    const ChartDataItems = tabularArray.map((item: number[]) => ({
      x: Math.abs(item[0]),
      y: item[1],
      name: plotName,
      displayName: setupName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  // This useEffect updates the graph chartdata with Coeff fit results.
  useEffect(() => {
    if (shockFitData && shockFitData.simResult && shockFitData.simResult.dataType === 'shock_fit') {
      if (shockFitData.simResult.pos === props.position) {
        // Update baseline setup/position chartData based on returned fit data
        if (shockFitData.simResult.output.chart_data) {
          let updatedChartData: ChartDataItem[] = [...chartData];
          const foundChartDataItem: ChartDataItem[] = updatedChartData.filter(item => item.id === props.setupId);
          const color = foundChartDataItem.length > 0 && foundChartDataItem[0].color ? foundChartDataItem[0].color : 'red';

          updatedChartData = [...updatedChartData.filter(item => item.id !== props.setupId),
            ...getChartDataItems(`${importedFileName} - FIT` || 'Fit Data', props.setupId, color,  shockFitData.simResult.output.chart_data)];

          setChartData(updatedChartData);
        }

        // Set coeff data to update UI Inputs
        setStateCoeffData(shockFitData.simResult.output as CoeffDataType);

        // Now need to take all data and issue a new Plot refresh request
        if (props.onUpdatePlotData) {
          // Gather updated Coeff Data and raise event for plot fetching request.
          const partData = {
            setupId: props.setupId,
            pos: props.position,
            partFormat: 'coeff',
            description: `${importedFileName} - FIT`,
            spec: Spec.GEN7,
            data: {
              CBleed: Number(shockFitData.simResult.output.CBleed),
              RBleed: Number(shockFitData.simResult.output.RBleed),
              CBlowOff: Number(shockFitData.simResult.output.CBlowOff),
              RBlowOff: Number(shockFitData.simResult.output.RBlowOff),
              CShimCurvature: Number(shockFitData.simResult.output.CShimCurvature),
              RShimCurvature: Number(shockFitData.simResult.output.RShimCurvature),
              CShimStiffness: Number(shockFitData.simResult.output.CShimStiffness),
              RShimStiffness: Number(shockFitData.simResult.output.RShimStiffness),
              JetType: shockFitData.simResult.output.JetType,
              AdjusterClicks: Number(shockFitData.simResult.output.AdjusterClicks),
              AdvancedParam: shockFitData.simResult.output.AdvancedParam,
              // Only include these properties if they exist
              ...(props.selectedPart?.data?.Compliance && { Compliance: props.selectedPart.data.Compliance }),
              ...(props.selectedPart?.data?.GasPressure && { GasPressure: props.selectedPart.data.GasPressure }),
              ...(props.selectedPart?.data?.hysteresis && { hysteresis: props.selectedPart.data.hysteresis }),
            },
          };
          props.onUpdatePlotData(partData as PartInput);
        }
      }
    }
  }, [shockFitData]);

  useEffect(() => {
    setPartType(props.partType);
  }, [props.partType]);

  useEffect(() => {
    setChartData(props.chartData);
  }, [props.chartData]);

  // Use effect to query server for fitting spring parts request
  useEffect(() => {
    if (customFitPart.length > 0) {
      requestPartFit({
        variables: { input: customFitPart, partType: 'shock' },
        fetchPolicy: 'network-only',
      })
        .catch(error => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error submitted fit request: ${error.message}`,
          });
        });
    }
  }, [customFitPart, requestPartFit]);

  useEffect(() => {
    // Refresh if import data or imported file name are updated.
  }, [importData, importedFileName]);

  // Due to async loading of initial selectedPart, set state in useEffect
  useEffect(() => {
    if (props.selectedPart?.data.CBleed) {
      setJetType(props.selectedPart?.data.JetType || 'Open Jet');
      setCBleed(props.selectedPart.data.CBleed || 0);
      setRBleed(props.selectedPart.data.RBleed || 0);
      setCBlowOff(props.selectedPart.data.CBlowOff || 0);
      setRBlowOff(props.selectedPart.data.RBlowOff || 0); // Corrected typo here
      setCShimStiffness(props.selectedPart.data.CShimStiffness || 0);
      setRShimStiffness(props.selectedPart.data.RShimStiffness || 0);
      setCShimCurvature(props.selectedPart.data.CShimCurvature || 0);
      setRShimCurvature(props.selectedPart.data.RShimCurvature || 0);
      setAdjusterClicks(props.selectedPart.data.AdjusterClicks || 0);
      setAdvancedParam(props.selectedPart.data.AdvancedParam || {});
    }
    if (props.selectedPart?.data.base_model) {
      setCompression_clicks_blowoff(props.selectedPart?.data.base_model.compression_clicks_blowoff || 0);// eslint-disable-line camelcase
      setCompression_clicks_high_speed(props.selectedPart?.data.base_model.compression_clicks_high_speed || 0);// eslint-disable-line camelcase
      setRebound_clicks_high_speed(props.selectedPart?.data.base_model.rebound_clicks_high_speed || 0);// eslint-disable-line camelcase
      setCompression_clicks_low_speed(props.selectedPart?.data.base_model.compression_clicks_low_speed || 0);// eslint-disable-line camelcase
      setRebound_clicks_low_speed(props.selectedPart?.data.base_model.rebound_clicks_low_speed || 0);// eslint-disable-line camelcase
      setCompression_valve(props.selectedPart?.data.base_model.compression_valve || '');// eslint-disable-line camelcase
      setRebound_valve(props.selectedPart?.data.base_model.rebound_valve || '');// eslint-disable-line camelcase
      setCompression_valve_spring(props.selectedPart?.data.base_model.compression_valve_spring || '');// eslint-disable-line camelcase
      setRebound_valve_spring(props.selectedPart?.data.base_model.rebound_valve_spring || '');// eslint-disable-line camelcase
    }
  }, [props.selectedPart]);

  const getImportCoords = (item: number[]) => {
    return {
      x: item[0],
      y: item[1],
    };
  };

  const handleFileSelected = (selectedFile: File) => {
    if (selectedFile) {
      ProcessShockFile(selectedFile)
        .then(processResult => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: `Import shock request ${processResult.success ? 'successful' : 'failed'} for file "${selectedFile.name}".`,
          });
          if (processResult.data.length > 0) {
            updateStateImportData(processResult.data);
          }
          return 'Success';
        }).catch(e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Import shock Error for file "${selectedFile.name}": ${e.message}`,
          });
          return 'Failed';
        });
    }
  };

  const handleOptionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = e.target as HTMLSelectElement;
    const hasChanged = e.target.value !== props.selectedPart?.data[id];
    switch (id) {
      case 'compression_valve':
        setCompression_valve(value);
        break;
      case 'rebound_valve':
        setRebound_valve(value);
        break;
      case 'compression_valve_spring':
        setCompression_valve_spring(value);
        break;
      case 'rebound_valve_spring':
        setRebound_valve_spring(value);
        break;
      default:
        break;
    }
    if (props.onUpdatePlotData && hasChanged) {
      // Gather updated Coeff Data and raise event for plot fetching request.
      props.onUpdatePlotData(getStatePartInput() as PartInput);
    }
  };

  // Function to determine if a number is in the range of numbers, if no number is passed in, return true;
  const inRange = (min?: number, max?: number, inValue?: string) => {
    if (!inValue) return true; // If no value is passed in the optional param...return true

    const numberValue = parseFloat(inValue || '');

    return !Number.isNaN(numberValue) && numberValue >= (min || -Infinity) && numberValue <= (max || Infinity);
  };

  // Function to get input styles if different or invalid entry
  const getInputStyle = (value1: string, value2: string, value?: string, min?: number, max?: number) => {
    const baseStyles = value1 !== value2 ? { color: 'black', background: 'yellow' } : {};

    // Apply updated diff and validation styles
    const finalStyles = { ...baseStyles, ...(inRange(min, max, value ?? '') ? {} : { border: '3px solid red' }) };

    return finalStyles;
  };

  // Function to get checkbox styles if values are different
  const isDifferentCkbxStyle = (value1: string, value2: string) => {
    return value1 !== value2 ? { color: 'yellow' } : {};
  };

  const getShockInputsUI = () => {
    if (!props.selectedPart) return null; // Is probably Tabular type and requires no Inputs
    if (partType === 'coeff') {
      return (
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLine}>
            <div className={styles.fieldValue} />
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>Comp</div>
              <div className={styles.fieldValue}>Reb</div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              Bleed
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <InputCheckbox
                  id="CBleed"
                  value={cBleed}
                  isDifferent={cBleed !== props.selectedPart.data.CBleed}
                  checked={useCBleed}
                  handleChange={handleChange}
                  handleCheck={() => setUseCBleed(!useCBleed)}
                  readOnly={props.readOnly}
                />
              </div>
              <div className={styles.fieldValue}>
                <InputCheckbox
                  id="RBleed"
                  value={rBleed}
                  isDifferent={rBleed !== props.selectedPart.data.RBleed}
                  checked={useRBleed}
                  handleChange={handleChange}
                  handleCheck={() => setUseRBleed(!useRBleed)}
                  readOnly={props.readOnly}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              Blowoff
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <InputCheckbox
                  id="CBlowOff"
                  onBlur={refreshPlotData}
                  value={cBlowOff}
                  isDifferent={cBlowOff !== props.selectedPart.data.CBlowOff}
                  checked={useCBlowOff}
                  handleChange={handleChange}
                  handleCheck={() => setUseCBlowOff(!useCBlowOff)}
                  readOnly={props.readOnly}
                />
              </div>
              <div className={styles.fieldValue}>
                <InputCheckbox
                  id="RBlowOff"
                  onBlur={refreshPlotData}
                  value={rBlowOff}
                  isDifferent={rBlowOff !== props.selectedPart.data.RBlowOff}
                  checked={useRBlowOff}
                  handleChange={handleChange}
                  handleCheck={() => setUseRBlowOff(!useRBlowOff)}
                  readOnly={props.readOnly}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              Stiffness
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <InputCheckbox
                  id="CShimStiffness"
                  onBlur={refreshPlotData}
                  value={cShimStiffness}
                  isDifferent={cShimStiffness !== props.selectedPart.data.CShimStiffness}
                  checked={useCStiffnes}
                  handleChange={handleChange}
                  handleCheck={() => setUseCStiffnes(!useCStiffnes)}
                  readOnly={props.readOnly}
                />
              </div>
              <div className={styles.fieldValue}>
                <InputCheckbox
                  id="RShimStiffness"
                  onBlur={refreshPlotData}
                  isDifferent={rShimStiffness !== props.selectedPart.data.RShimStiffness}
                  value={rShimStiffness}
                  checked={useRStiffnes}
                  handleChange={handleChange}
                  handleCheck={() => setUseRStiffnes(!useRStiffnes)}
                  readOnly={props.readOnly}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              Curvature
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <InputCheckbox
                  id="CShimCurvature"
                  onBlur={refreshPlotData}
                  value={cShimCurvature}
                  isDifferent={cShimCurvature !== props.selectedPart.data.CShimCurvature}
                  checked={useCCurvature}
                  handleChange={handleChange}
                  handleCheck={() => setUseCCurvature(!useCCurvature)}
                  readOnly={props.readOnly}
                />
              </div>
              <div className={styles.fieldValue}>
                <InputCheckbox
                  id="RShimCurvature"
                  onBlur={refreshPlotData}
                  value={rShimCurvature}
                  isDifferent={rShimCurvature !== props.selectedPart.data.RShimCurvature}
                  checked={useRCurvature}
                  handleChange={handleChange}
                  handleCheck={() => setUseRCurvature(!useRCurvature)}
                  readOnly={props.readOnly}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              Clicks
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <InputGroup
                  id="AdjusterClicks"
                  onBlur={refreshPlotData}
                  value={adjusterClicks}
                  onChange={handleChange}
                  style={getInputStyle(adjusterClicks, props.selectedPart.data.AdjusterClicks)}
                  disabled={props.readOnly}
                />
              </div>
              <div className={styles.fieldValue} />
            </div>
          </div>
          <div className={styles.radioGroupContainer}>
            <div className={styles.radioGroup}>
              <RadioGroup
                name={radioGroupName}
                onChange={handleChange}
                selectedValue={jetType}
                disabled={props.readOnly}
              >
                <Radio label="Comp Jet" value="Comp Jet" style={props.selectedPart.data.JetType === 'Comp Jet' ? isDifferentCkbxStyle(jetType, props.selectedPart.data.JetType) : {}} />
                <Radio label="Reb Jet" value="Reb Jet" style={props.selectedPart.data.JetType === 'Reb Jet' ? isDifferentCkbxStyle(jetType, props.selectedPart.data.JetType) : {}}  />
                <Radio label="Open Jet" value="Open Jet" style={props.selectedPart.data.JetType === 'Open Jet' ? isDifferentCkbxStyle(jetType, props.selectedPart.data.JetType) : {}}  />
              </RadioGroup>
            </div>
          </div>
        </div>
      );
    } else if (partType === 'vrp') {
      return (
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLine}>
            <div className={styles.fieldValue} />
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>Comp</div>
              <div className={styles.fieldValue}>Reb</div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              LS Clicks
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <InputGroup
                  id="compression_clicks_low_speed"
                  onBlur={refreshPlotData}
                  value={compression_clicks_low_speed}// eslint-disable-line camelcase
                  onChange={handleChange}
                  disabled={props.readOnly}
                  style={getInputStyle(
                    compression_clicks_low_speed,
                    props.selectedPart.data.base_model.compression_clicks_low_speed,
                    compression_clicks_low_speed.toString(), // eslint-disable-line camelcase
                    0,
                    60,
                  )} // eslint-disable-line camelcase
                />
              </div>
              <div className={styles.fieldValue}>
                <InputGroup
                  id="rebound_clicks_low_speed"
                  onBlur={refreshPlotData}
                  value={rebound_clicks_low_speed}// eslint-disable-line camelcase
                  onChange={handleChange}
                  disabled={props.readOnly}
                  style={getInputStyle(
                    rebound_clicks_low_speed,
                    props.selectedPart.data.base_model.rebound_clicks_low_speed,
                    rebound_clicks_low_speed.toString(), // eslint-disable-line camelcase
                    0,
                    60
                  )} // eslint-disable-line camelcase
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              Valve
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <select
                  className={styles.fieldSelectorContainer}
                  name="compression_valve"
                  id="compression_valve"
                  value={compression_valve} // eslint-disable-line camelcase
                  onChange={handleOptionChange}
                  disabled={props.readOnly}
                  style={getInputStyle(compression_valve, props.selectedPart.data.base_model.compression_valve)} // eslint-disable-line camelcase
                >
                  <option value="G47">G47</option>
                  <option value="G67">G67</option>
                  <option value="H47">H47</option>
                </select>
              </div>
              <div className={styles.fieldValue}>
                <select
                  className={styles.fieldSelectorContainer}
                  name="rebound_valve"
                  id="rebound_valve"
                  value={rebound_valve} // eslint-disable-line camelcase
                  onChange={handleOptionChange}
                  disabled={props.readOnly}
                  style={getInputStyle(rebound_valve, props.selectedPart.data.base_model.rebound_valve)} // eslint-disable-line camelcase
                >
                  <option value="G47">G47</option>
                  <option value="G67">G67</option>
                  <option value="H47">H47</option>
                </select>
              </div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              Spring
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <select
                  className={styles.fieldSelectorContainer}
                  name="compression_valve_spring"
                  id="compression_valve_spring"
                  value={compression_valve_spring} // eslint-disable-line camelcase
                  onChange={handleOptionChange}
                  disabled={props.readOnly}
                  style={getInputStyle(compression_valve_spring, props.selectedPart.data.base_model.compression_valve_spring)} // eslint-disable-line camelcase
                >
                  <option value="20N">20N</option>
                  <option value="30N">30N</option>
                  <option value="40N">40N</option>
                  <option value="50N">50N</option>
                </select>
              </div>
              <div className={styles.fieldValue}>
                <select
                  className={styles.fieldSelectorContainer}
                  name="rebound_valve_spring"
                  id="rebound_valve_spring"
                  value={rebound_valve_spring} // eslint-disable-line camelcase
                  onChange={handleOptionChange}
                  disabled={props.readOnly}
                  style={getInputStyle(rebound_valve_spring, props.selectedPart.data.base_model.rebound_valve_spring)} // eslint-disable-line camelcase
                >
                  <option value="20N">20N</option>
                  <option value="30N">30N</option>
                  <option value="40N">40N</option>
                  <option value="50N">50N</option>
                </select>
              </div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              HS Clicks
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <InputGroup
                  id="compression_clicks_high_speed"
                  onBlur={refreshPlotData}
                  value={compression_clicks_high_speed}// eslint-disable-line camelcase
                  onChange={handleChange}
                  disabled={props.readOnly}
                  style={getInputStyle(
                    compression_clicks_high_speed,
                    props.selectedPart.data.base_model.compression_clicks_high_speed,
                    compression_clicks_high_speed.toString(), // eslint-disable-line camelcase
                    0,
                    40
                  )} // eslint-disable-line camelcase
                />
              </div>
              <div className={styles.fieldValue}>
                <InputGroup
                  id="rebound_clicks_high_speed"
                  onBlur={refreshPlotData}
                  value={rebound_clicks_high_speed}// eslint-disable-line camelcase
                  onChange={handleChange}
                  disabled={props.readOnly}
                  style={getInputStyle(
                    rebound_clicks_high_speed,
                    props.selectedPart.data.base_model.rebound_clicks_high_speed,
                    rebound_clicks_high_speed.toString(), // eslint-disable-line camelcase
                    0,
                    40
                  )} // eslint-disable-line camelcase
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldLine}>
            <span className={styles.fieldLabel}>
              Blow Off Clicks
            </span>
            <div className={styles.fieldValueContainer}>
              <div className={styles.fieldValue}>
                <InputGroup
                  id="compression_clicks_blowoff"
                  onBlur={refreshPlotData}
                  value={compression_clicks_blowoff}// eslint-disable-line camelcase
                  onChange={handleChange}
                  disabled={props.readOnly}
                  style={getInputStyle(
                    compression_clicks_blowoff,
                    props.selectedPart.data.base_model.compression_clicks_blowoff,
                    compression_clicks_blowoff.toString(), // eslint-disable-line camelcase
                    0,
                    50
                  )} // eslint-disable-line camelcase
                />
              </div>
              <div className={styles.fieldValue} />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  // Make absolute for displaying.
  const makeAbsolute = (inputData: DataPoint[]): DataPoint[] => {
    return inputData.map(dataPoint => ({
      ...dataPoint,
      x: Math.abs(dataPoint.x),
    }));
  };

  const isAbsolute = (inputData: DataPoint[]): boolean => {
    return inputData.every(dataPoint => dataPoint.x >= 0);
  };

  const formattedImportData = makeAbsolute(importData || []);

  const disableFit = !importData || importData.length === 0 || !isAbsolute(importData);

  // If is a coeff format, but has importData with Absolute value, part needs to be fit first, Disable Commit else enabled.
  const disableCommit = (partType === 'coeff' && importData && importData.length > 0 && isAbsolute(importData) && !canCommit);

  return (
    <div className={styles.singleChartContainer}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>{props.position}</span>
        <PartSelector
          className={styles.partSelector}
          initialPart={props.selectedPart}
          parts={props.parts || []}
          onChange={partId => props.onChangeHandler?.(props.path, partId, props.position, undefined)}
          hideDetails
          disabled={props.readOnly}
        />
        <div className={styles.importSelector}>
          <FilePicker onFileSelected={handleFileSelected} selectedFile={importedFileName} buttonText="Import" extensions=".csv, .pvp" readOnly={props.readOnly} />
        </div>
        <Button
          disabled={disableFit}
          className={styles.chartHeaderButton}
          small
          onClick={handleFitPart}
        >
          Fit
        </Button>
        <Button
          disabled={disableCommit}
          className={styles.chartHeaderButton}
          small
          onClick={handleCommitPart}
        >
          Commit
        </Button>
        <SavePartModal
          isOpen={isSavePartDialogOpen}
          onClose={() => setIsSavePartDialogOpen(false)}
          onCommitSuccess={onCommittedPart}
          partData={GetDBPartData()}
          partConfigId={props.partConfigId}
          spec={props.spec}
        />
      </div>
      <div className={styles.chartMainSection}>
        <div className={styles.chart}>
          <Chart
            data={chartData}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            tooltip
            xToolTipLabel={xToolTipLabel}
            yToolTipLabel={yToolTipLabel}
            importData={formattedImportData}
          />
        </div>
        {getShockInputsUI()}
      </div>
    </div>
  );
};
