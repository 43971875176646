import { createSlice } from '@reduxjs/toolkit';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { get } from 'lodash';

import { State } from 'reducers';

export interface IndividualTableSettings {
  tableFilters?: ColumnFiltersState;
  tableSorting: SortingState;
}

export interface Settings {
  setupSummary: IndividualTableSettings;
  simSummary: IndividualTableSettings;
  environmentSummary: IndividualTableSettings;
  simReportingSummary: IndividualTableSettings;
  sweepReportingSummary: IndividualTableSettings;
}

export interface TableViewState {
  settings: Settings;
}

export const initialState = {
  settings: {
    setupSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    simSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    environmentSummary: {
      tableFilters: [],
      tableSorting: [],
    },
    simReportingSummary: {
      tableSorting: [],
    },
    sweepReportingSummary: {
      tableSorting: [],
    },
  },
};

export const tableViewSlice = createSlice({
  name: 'tableView',
  initialState,
  reducers: {
    setSetupSummaryView: (state, { payload }) => {
      if (!state.settings.setupSummary) {
        state.settings.setupSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.setupSummary.tableFilters = payload.filters;
      state.settings.setupSummary.tableSorting = payload.sorting;
    },
    setSimSummaryView: (state, { payload }) => {
      if (!state.settings.simSummary) {
        state.settings.simSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.simSummary.tableFilters = payload.filters;
      state.settings.simSummary.tableSorting = payload.sorting;
    },
    setEnvironmentSummaryView: (state, { payload }) => {
      if (!state.settings.environmentSummary) {
        state.settings.environmentSummary = { tableFilters: [], tableSorting: [] };
      }
      state.settings.environmentSummary.tableFilters = payload.filters;
      state.settings.environmentSummary.tableSorting = payload.sorting;
    },
    setSimReportingSummaryView: (state, { payload }) => {
      if (!state.settings.simReportingSummary) {
        state.settings.simReportingSummary = { tableSorting: [] };
      }
      state.settings.simReportingSummary.tableSorting = payload.sorting;
    },
    setSweepReportingSummaryView: (state, { payload }) => {
      if (!state.settings.sweepReportingSummary) {
        state.settings.sweepReportingSummary = { tableSorting: [] };
      }
      state.settings.sweepReportingSummary.tableSorting = payload.sorting;
    },
  },
});
export default tableViewSlice.reducer;

export const selectSetupSummaryView = (state: State) => get(state, 'tableView.settings.setupSummary', { tableFilters: [], tableSorting: [] });
export const selectSimSummaryView = (state: State) => get(state, 'tableView.settings.simSummary', { tableFilters: [], tableSorting: [] });
export const selectEnvironmentSummaryView = (state: State) => get(state, 'tableView.settings.environmentSummary', { tableFilters: [], tableSorting: [] });
export const selectSimReportingSummaryView = (state: State) => get(state, 'tableView.settings.simReportingSummary', { tableSorting: [] });
export const selectSweepReportingSummaryView = (state: State) => get(state, 'tableView.settings.sweepReportingSummary', { tableSorting: [] });

export const selectors = {
  selectSetupSummaryView,
  selectSimSummaryView,
  selectEnvironmentSummaryView,
  selectSimReportingSummaryView,
  selectSweepReportingSummaryView,
};
