import React, { useState } from 'react';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent } from '@blueprintjs/core';
import styles from './index.module.css';
import AppToaster from 'helpers/toaster';
import { ApiRequest } from 'api';
import { GQLSimWorksheet } from 'types';
import { useSubscriptionContext } from 'context/SubscriptionContext';

interface RunButtonProps {
  simOutlineIds: number[]
  text?: string
  transparent?: boolean
  disabled?: boolean
  worksheet: GQLSimWorksheet
}

const RunButton : React.FC<RunButtonProps> = (props: RunButtonProps) => {
  const { simOutlineIds, text, transparent, disabled, worksheet } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getExistingProgress } = useSubscriptionContext();

  const runSimOutlines = async () => {
    setIsLoading(true);
    if (!worksheet) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Worksheet is not defined',
      });
      return;
    }

    try {
      await ApiRequest.post('/execution-plans/simserver', { worksheetId: worksheet.id, simOutlineIds });
    } catch (e) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Sim submission error',
      });
    } finally {
      setIsLoading(false);
      getExistingProgress();
    }
  };

  return (
    <Button
      onClick={() => runSimOutlines()}
      minimal={transparent}
      disabled={disabled}
      loading={isLoading}
    >
      <FontAwesomeIcon icon={faPlay} />
      {text ? <span className={styles.paddingLeft}>{text}</span> : null}
    </Button>
  );
};

export default RunButton;
